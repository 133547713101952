import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSaverService } from '@app/shared/file-saver';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class ReportsApiService {
  constructor(
    protected http: HttpClient,
    protected fileSaverService: FileSaverService
  ) {}

  executeReport(reportName: string, request: object) {
    const req = JSON.stringify(request);
    return this.http.post(`${environment.apiUrl}/reporting/${reportName}`, req, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadReport(reportName: string, request: object) {
    return this.executeReport(reportName, request).pipe(
      tap(res => {
        const filename = res.headers.get('content-disposition')
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .replace(/['"]/g, '')
          .trim();
        this.fileSaverService.saveAs(res.body, filename);
      })
    );
  }

  checkReportExists(name: string) {
    return this.http.get<boolean>(`${environment.apiUrl}/reporting/exists/${name}`);
  }
}
