import { Beneficiary, DebitCreditReasons } from '@app/shared/api';
import { DispatchItem } from './dispatch-item';
import { Doctor } from './doctor';
import { InvoiceType } from './invoice-type';
import { Pharmacy } from './pharmacy';
import { Prescription } from './prescription';
import { User } from './user';

export interface DispatchAdjustment {
  id: number;
  reason: string;
  amount: number;
}

export enum DispatchStatus {
  'Dispatched' = 'Dispatched',
  'Canceled' = 'Canceled',
  'Audited' = 'Audited'
}
export interface Dispatch {
  id: number;
  date: Date;
  pharmacyId: number;
  prescriptionId: number;
  prescriptionDate: Date;
  // doctorId?: number;
  // doctorLicense?: string;
  // doctorLastName?: string;
  // doctorFirstName?: string;
  // doctorFullName?: string;
  beneficiaryId?: number;
  userId: number;
  status: DispatchStatus;
  validationCode: string;
  presentationCode: string;
  prescriptionNumber: string;
  invoiceTypeId: number;
  invoiceNumber: string;
  observations: string;
  return: boolean;
  // returnReason: string;
  orderNumber: string;
  pharmacyBatchId: number;
  createdAt?: Date;
  updatedAt?: Date;
  items?: DispatchItem[];
  adjustments?: DispatchAdjustment[];
  beneficiary?: Beneficiary;
  pharmacy?: Pharmacy;
  prescription?: Prescription;
  invoiceType?: InvoiceType;
  returnReason?: DebitCreditReasons;
  returnReasonId?: number | any;
  drugCompanyId?: number;
  auditUser?: User
  documentsReceptionDate?: Date,
  documentsDeliveryNoteNumber: string,
  documentsHaveDeliveryNote: boolean,
  documentsHaveTraceabilityProof: boolean,
  documentsHaveOriginalPrescription: boolean,
  documentsHaveDies: boolean,
  documentsAreComplete: boolean,
  chargeTotal: number,
  liquidatedTotal: number,
  dueTotal : number
}
