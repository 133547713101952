/* tslint:disable:max-line-length */
// FIXME: Using \u200B Zero width character for disabling chrome credit card autofill

export default {
  language: 'Español',
  COMMON: {
    startDate: 'Fecha desde',
    endDate: 'Fecha hasta',
    view: 'Ver',
    create: 'Crear',
    save: 'Actualizar',
    execute: 'Ejecutar',
    continue: 'Continuar',
    back: 'Volver',
    id: 'Id',
    createdAt: 'Creación',
    updatedAt: 'Actualizado',
    createdBy: 'Creado por',
    error: 'Error',
    noResults: 'Sin resultados',
    pagerCount: "'{first} - {last} de {totalRecords}'",
    filters: 'Filtros',
    select: 'Seleccionar',
    add: 'Añadir',
    edit: 'Editar',
    delete: 'Eliminar',
    remove: 'Desasociar',
    removeSelected: 'Desasociar Seleccionados',
    clearFields: 'Limpiar Búsqueda',
    undo: 'Deshacer',
    deleteSelected: 'Eliminar Seleccionados',
    process: 'Procesar',
    manuallyProcess: 'Procesar Manual',
    sendEmail: 'Enviar Email',
    resendEmail: 'Reenviar Email',
    print: 'Imprimir',
    addBatch: 'Nuevo',
    DOCUMENT_TYPES: {
      DNI: 'DNI',
      CUIL: 'CUIL',
      CI: 'CI',
      LC: 'LC',
      LE: 'LE',
    },
    close: 'Cerrar',
    cancel: 'Cancelar',
    cancelSelection: 'Cancelar Selección',
    accept: 'Aceptar',
    yes: 'Sí',
    no: 'No',
    search: 'Buscar',
    copyInClipboard: 'Copiar en Portapapeles',
    detail: 'Detalle',
    path: 'Ruta',
    errorDetails: 'Detalles de Error',
    showDetails: 'Ver Detalle',
    manageFiles: 'Documentos',
    optionAll: '(Todos)',
    extraSmall: 'Extra Pequeño',
    verySmall: 'Muy Pequeño',
    small: 'Pequeño',
    normal: 'Normal',
    large: 'Grande',
    veryLarge: 'Muy Grande',
    general: 'General',
    pay: 'Pagar',
    payRevert: 'Revertir Pago',
    LIQUIDATED_AMOUNT: {
      liquidatedAmount: 'Importe Total',
      receptionDate: 'Fecha Pago',
      invoiceNumber: 'Comprobante Pago'
    },
    drugCompanyTitle: 'Info droguería',
    receiveDocumentation: 'Recibir Documentación',
    queryLogisticStatus: 'Consultar Estado Logistica',
    CALENDAR: {
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: '1',
      DAY_NAMES: {
        day1: 'Domingo',
        day2: 'Lunes',
        day3: 'Martes',
        day4: 'Miércoles',
        day5: 'Jueves',
        day6: 'Viernes',
        day7: 'Sábado',
      },
      DAY_NAMES_SHORT: {
        day1: 'Dom',
        day2: 'Lun',
        day3: 'Mar',
        day4: 'Mié',
        day5: 'Jue',
        day6: 'Vie',
        day7: 'Sáb',
      },
      DAY_NAMES_MIN: {
        day1: 'D',
        day2: 'L',
        day3: 'M',
        day4: 'X',
        day5: 'J',
        day6: 'V',
        day7: 'S',
      },
      MONTH_NAMES: {
        month1: 'Enero',
        month2: 'Febrero',
        month3: 'Marzo',
        month4: 'Abril',
        month5: 'Mayo',
        month6: 'Junio',
        month7: 'Julio',
        month8: 'Agosto',
        month9: 'Septiembre',
        month10: 'Octubre',
        month11: 'Noviembre',
        month12: 'Diciembre',
      },
      MONTH_NAMES_SHORT: {
        month1: 'Ene',
        month2: 'Feb',
        month3: 'Mar',
        month4: 'Abr',
        month5: 'May',
        month6: 'Jun',
        month7: 'Jul',
        month8: 'Ago',
        month9: 'Sep',
        month10: 'Oct',
        month11: 'Nov',
        month12: 'Dic',
      },
      today: 'Hoy',
      clear: 'Borrar',
    },
    processSelected: 'Procesar seleccionados',
    processAll: 'Procesar todo',
    loading: 'Cargando...',
    loadingData: 'Cargando datos...',
    loadingPrint: 'Cargando datos para la impresión. Esto puede demorar algunos minutos...'
  },
  ERRORS: {
    INVALID_PRESCRIPTION_DATE: 'La fecha de prescripción seleccionada no es válida. Debe ser posterior al {minimumValidDate}. El período de validez es de {validityDays} días.',
    INVALID_PRESCRIPTION_DATE_WITHOUT_DETAIL: 'Hubo un problema en la validación de FechaPrescripción. Por favor contactarse con Atención a Red de Farmacias',
    FUTURE_PRESCRIPTION_DATE: 'La fecha de prescripción no puede ser futura',
    birthdateIncorrect: 'La fecha de nacimiento es incorrecta',
    unknown: 'Error desconocido',
    unhandledServerError: 'Error en servidor',
    noInternetConnection: 'No hay conexión a internet',
    cantReachServer: 'No se pudo contactar con el servidor',
    passwordsNotEqual: 'Las contraseñas deben ser iguales',
    passwordFormat:
      'La contraseña debe ser de 6 o más caracteres y debe cumplir con 3 o más de las siguientes condiciones:<br/>' +
      'Al menos una letra mayúscula<br/>' +
      'Al menos una letra minúscula<br/>' +
      'Al menos un número<br/>' +
      'Al menos un símbolo especial',
    invalidResetPasswordToken:
      'El token para restablecer tu contraseña ha expirado o es invalido',
    duplicatedItem: 'Hay {collection} duplicadas',
    itemsRequired:
      '{count, plural, one {Al menos un {item} es requerido} other {{count} o más {collection} son requeridos}}',
    relationConstraint:
      'No se puede eliminar {reference}, está siendo referenciado por otra entidad',
    passwordChangeSameError: 'No se puede usar una contraseña anterior.',
    databaseError: "Hay una restricción en la base de datos que impide realizar la operación solicitada.",
    futureDate: "No se permiten fechas futuras",
    limitDate: "El límite de fechas es 90 días",
  },
  SHARED: {
    COMPONENTS: {
      ADDRESS: {
        header: 'Domicilio',
        save: 'Guardar',
        cancel: 'Cancelar',
        autocomplete: 'Buscar dirección',
        country: 'País',
        region: 'Provincia',
        city: 'Ciudad',
        street: 'Calle',
        streetNumber: 'Número de puerta',
        floor: 'Piso',
        apartment: 'Departamento',
        zipCode: 'Código Postal',
        mobilePhone: 'Teléfono móvil',
        landPhone: 'Teléfono fijo',
        delete: 'X',
        deleteAll: 'Borrar todos los datos de domicilio',
      },
      DELETE_DIALOG: {
        header: 'Eliminar {item}',
        message: '¿Quieres eliminar este elemento?',
        headerMultiple: 'Eliminar Elementos',
        messageMultiple: '¿Quieres eliminar estos elementos?',
        accept: 'Eliminar',
        reject: 'Cancelar',
      },
      ENTITY_TABLE: {
        itemDeleted: '{item} ha sido eliminado',
        itemCanceled: 'Cancelado exitosamente',
        itemDeletedError: 'Ha ocurrido un error al intentar eliminar {item}',
        config: 'Configurar Tabla',
        reset: 'Restablecer',
        columns: 'Columnas',
        row: 'Fila',
        size: 'Tamaño',
        actionsCollapse: 'Expandir/Colapsar',
        actionsPosition: 'Posición de Acciones',
        actionsBoth: 'Ambos',
        actionsLeft: 'Izquierda',
        actionsRight: 'Derecha',
      },
      IMAGE_UPLOAD: {
        choose: 'Elegir',
        cancel: 'Remover',
        invalidFileSizeMessageSummary: "'{0}': tamaño de archivo invalido, ",
        invalidFileSizeMessageDetail: "el límite máximo es '{0}'.",
        invalidFileTypeMessageSummary: "'{0}': tipo de archivo invalido, ",
        invalidFileTypeMessageDetail: "tipos permitidos: '{0}'",
        invalidFileLimitMessageSummary: 'Máximo número de archivos alcanzado, ',
        invalidFileLimitMessageDetail: "el límite es '{0}'",
      },
      FORMS: {
        ERRORS: {
          required: 'Campo requerido',
          cuit: 'CUIT inválido',
          gln: 'GLN inválido',
          pattern: 'Valor inválido',
          email: 'Email inválido',
          min: 'Valor mínimo: {min}',
          max: 'Valor máximo: {max}',
          minlength: 'Longitud mínima: {requiredLength}',
          maxlength: 'Longitud máxima: {requiredLength}',
          issuedMax:
            'No se pueden entregar más medicamentos que los autorizados',
          dateRangeFields:
            '{endCaption, select, undefined{La fecha} other{{endCaption}}} debe ser posterior a {startCaption}',
          fieldMatch: '{field2Caption} debe ser igual a {field1Caption}',
          fieldsRequired: 'Debe completar todos los campos requeridos',
          invalidDateOrder: "La fecha de inicio debe ser menor o igual a la fecha final",
          requiredFinancierSelect: "El campo de financiador es obligatorio",
          requiredStartDate: "La fecha de inicio es obligatoria",
          requiredEndDate: "La fecha de fin es obligatoria",
          dateRangeTooLarge: "La diferencia entre las fechas no puede ser mayor a 90 días"

        },
      },
    },
    PAGES: {
      ACCESS_DENIED: {
        title: 'Acceso denegado',
        description: 'No estás autorizado para acceder a este recurso.',
      },
      ERROR: {
        title: 'Ha ocurrido un error',
        description: 'Contacta a tu administrador del sistema.',
      },
      NOT_FOUND: {
        title: 'Página no encontrada',
        description: 'El recurso que estás buscando no existe.',
      },
    },
    FILES: {
      title: 'Archivos de {item} {display}',
      addFile: 'Agregar Archivo',
      previewFile: 'Visualizar Archivo',
      downloadFile: 'Descargar Archivo',
      selectedFiles:
        '{selected} {selected, plural, one{archivo seleccionado} other{archivos seleccionados}}',
      emptyList: 'No se han agregado archivos',
      backToList: 'Volver a Listado',
      backToEdit: 'Volver a {item}',
      backToShow: 'Volver a {item}',
      fileCreated: 'Archivo agregado',
      fileUploadError: 'Error al subir archivo',
      fileRemoved: 'Archivo eliminado',
      addLogo: 'Subir logo',
      logo: 'Logo de {item} {display}'
    },
    DYNAMIC_FORMS: {
      TYPES: {
        ADDRESS: {
          title: 'Domicilio',
        },
        MULTI_SELECT: {
          selectedItemsLabel: "'{'0'}' items seleccionados",
        },
      },
    },
  },
  ROLES: {
    Admin_AdministrationRoutes: 'Via de Administración',
    Admin_Alfabeta: 'Alfabeta',
    Admin_AuditLogs: 'Logs de Auditoría',
    Admin_CommercialAgreements: 'Convenios Comerciales',
    Admin_Beneficiaries: 'Beneficiarios',
    Beneficiary: 'Beneficiario solo lectura',
    BeneficiaryTreatment: 'Beneficiario eliminar tratamiento',
    Admin_CoveragePrograms: 'Programas de Cobertura',
    Admin_Diagnoses: 'Diagnósticos',
    Admin_DistributionTypes: 'Tipos de Distribución',
    Admin_Doctors: 'Médicos',
    Admin_DosageForms: 'Forma Farmacéutica',
    Admin_DrugCompanies: 'Droguerías',
    Admin_Financiers: 'Financiadores',
    Admin_Groups: 'Roles',
    Admin_Laboratories: 'Laboratorios',
    Admin_LogisticOperators: 'Operadores Logísticos',
    Admin_Incidents: 'Incidencias',
    Admin_IncidentsAreas: 'Áreas de Incidencias',
    Admin_IncidentsReason: 'Razones de Incidencias',
    Admin_MaterialTypes: 'Tipos de Material',
    Admin_MedicalSpecialities: 'Especialidades',
    Admin_DebitCreditReasons: 'Motivos Debito Credito',
    Admin_Medications: 'Medicamentos',
    Admin_MisValidaciones: 'Mis Validaciones',
    Admin_Monodrugs: 'Monodroga',
    Admin_Pathologies: 'Patologías',
    Admin_PaybackEntities: 'Entidades Recupero',
    Admin_Pharmacies: 'Farmacias',
    Pharmacy: 'Farmacia solo Lectura',
    Admin_PharmacologicalActions: 'Acción Farmacológica',
    Admin_PharmacyGroups: 'Entidades Farmacéuticas',
    Admin_PotencyUnits: 'Unidad de Potencia',
    Admin_Prescriptions: '',
    Admin_SAPDistributionRules: 'Normas de Reparto SAP',
    Admin_UnitTypes: 'Tipo de Unidad',
    Admin_Users: 'Usuarios',
    Admin_Vademecums: 'Vademecums',
    Admin_Zones: 'Lugares',
    Audit: 'Auditar',
    Authorize: 'Autorizar',
    Cancel: 'Cancelar',
    Distribute: 'Distribuir',
    Distribute_SAP: 'Informar a SAP',
    Distribute_updateManually: 'Proceso Manual',
    Distribute_America: 'Informar a America',
    Beneficiary_import: 'Importar padrón de beneficiarios',
    Liquidate: 'Liquidar',
    Liquidate_InverseLogistics: 'Logística Inversa',
    Liquidate_Dispatches: 'Presentaciones',
    Liquidate_PharmacyBatches: 'Lotes Farmacia',
    Liquidate_Liquidations: 'Liquidaciones',
    Liquidate_ExportAmerica: 'Exportar a America',
    Liquidate_Pay: 'Pagar',
    Liquidate_EditGenerated: 'Editar Generadas',
    Liquidate_EditProcess: 'Editar Procesadas',
    Liquidate_EditPay: 'Editar Pagadas',
    Liquidate_Add: 'Agregar Liquidación',
    Liquidate_Billing: 'Facturación',
    Liquidate_IndustryRecovery: 'Recupero Industria',
    Liquidate_DispatchesProcess: 'Procesar Despachos',
    Liquidate_DispatchesEdit: 'Editar Despachos',
    Prescribe: 'Prescribir',
    Prescriptions: 'Listar',
    Prescription_Edit: 'Editar Prescripciones',
    Prescription_ReadOnly: 'Editar Prescripciones solo lectura',
    Prescription_Notify: 'Notificar Prescripciones',
    Prescription_Log: 'Logs de Estado de Prescripciones',
    Reports: 'Reportes',
    Validate: 'Validar',
    Admin_Reports: 'Agregar Reporte',
    Liquidate_ItemDispatch: 'Editar Normas de Liquidación',
    Liquidate_Update_AgreementRules: 'Actualizar Normas de Liquidación',
    Admin_Companies: 'Empresas',
  },
  ENTITIES: {
    COMMON: {
      newItemTitle:
        'Creando {gender, select, female{nueva} other{nuevo}} {item}',
      addItem: 'Agregar {item}',
      newItem: '{gender, select, female{Nueva} other{Nuevo}} {item}',
      showItem: 'Ver {item}',
      editItem: 'Editar {item}',
      saveItem: 'Guardar {item}',
      removeItem: 'Eliminar {item}',
      selectItem: 'Seleccionar {item}',
      collectionList: 'Listado de {collection}',
      noItemsFound: 'No se han encontrado {collection}',
      emptyList: 'No se han agregado {collection}',
      itemAlreadyExistsError:
        'Ya existe {gender, select, female{una} other{un}} {item} con los valores seleccionados',
      itemsEditingError: 'No pueden quedar {collection} en modo edición',
      itemsDuplicatedError:
        'Hay {collection} {gender, select, female{duplicadas} other{duplicados}}',
      itemCreated: '{item} {gender, select, female{creada} other{creado}}',
      itemUpdated: '{item} {gender, select, female{guardada} other{guardado}}',
      itemRemoved:
        '{item} {gender, select, female{eliminada} other{eliminado}}',
      editItemTitle: '{item} {display}',
      selectedItems:
        '{selected} {selected, plural, one{{item} {gender, select, female{seleccionada} other{seleccionado}}} other{{collection} {gender, select, female{seleccionadas} other{seleccionados}}}}',
      AUTOCOMPLETE: {
        noResults: 'No se han encontrado resultados',
        noResultsMinLength:
          'Para buscar escriba al menos {minLength} caracteres',
      },
      FILTERS: {
        default: 'Por Defecto',
        '<': 'Menor',
        '<=': 'Menor o Igual',
        '<>': 'Diferente',
        '=': 'Igual',
        '>': 'Mayor',
        '>=': 'Mayor o Igual',
        between: 'Entre',
        contains: 'Contiene',
        endswith: 'Termina con',
        notcontains: 'No Contiene',
        startswith: 'Comienza con',
      },
    },
    ACCOUNTABLE_DOCUMENT: {
      item: 'Comprobante Administrativo',
      billingItem: 'Comprobante Pre Facturación',
      collection: 'Comprobantes Administrativos',
      gender: 'male',
      FIELDS: {
        invoiceTypeId: 'Id Tipo Comprobante',
        invoiceType: 'Tipo Comprobante',
        documentDate: 'Fecha del comprobante',
        documentNumber: 'Número de Comprobante Administrativo',
        documentTotal: 'Total Comprobante',
        documentPresentationDate: 'Fecha de Presentación',
        liquidationPeriod: 'Periodo Liquidación',
      }
    },
    ADDRESS: {
      item: 'Domicilio',
      collection: 'Domicilios',
      gender: 'male',
    },
    ADMINISTRATION_ROUTE: {
      item: 'Vía de Administración',
      collection: 'Vías de Administración',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
      },
    },
    AUDIT_LOG: {
      item: 'Log de Auditoria',
      collection: 'Logs de Auditoria',
      gender: 'male',
      FIELDS: {
        transactionId: 'Transacción',
        operation: 'Operación',
        entity: 'Entidad',
        entityId: 'Id Entidad',
        action: 'Acción',
        data: 'Datos',
        userId: 'Id Usuario',
        user: 'Usuario',
        userContext: 'Contexto de Usuario',
        createdAt: 'Fecha',
      },
    },
    AUDITED_ACCOUNTABLE_ITEM: {
      item: 'Elemento contable auditado',
      collection: 'Elementos contables auditados',
      gender: 'male',
      FIELDS: {
        id: 'Id Elemento Auditado',
        dispatchItemId: 'Id Item Despacho',
        dispatchItem: 'Id Item Despacho',
        auditedBillingAmount: 'Importe Facturado',
        auditedRefundingAmount: 'Importe Recuperado',
        auditedPharmacyPayment: 'Pago de Farmacia auditado',
        auditedDrugCompanyPayment: 'Pago de drogueria auditado',
        dispatchPosition: 'Orden despacho',
        pharmacyCity: 'Farmacia Localidad',
        pharmacyRegion: 'Farmacia Provincia',
        pharmacyCUIT: 'CUIT Farmacia',
        dispatchDate: 'Fecha Venta Despacho',
        dispatchCoverageProgram: 'Programa de Cobertura Despacho',
        dispatchCommercialAgreement: 'Convenio Comercial Despacho',
        liquidationPeriod: 'Periodo Liquidación',
        dispatchItemAuditedTotalPrice: 'PVP Total',
        dispatchItemAuditedFinancierCoverage: 'Cobertura',
        dispatchItemAuditedFinancierTotal: 'Importe Financiador',
        dispatchItemBilledPercent: 'Descuento Comercial',
        dispatchItemStatus: 'Estado Item Despacho',
        dispatchItemAuditedAmount: 'Cantidad',
        dispatchItemLiquidateValue: 'Valor',
        dispatchItemAuditedPaymentTotal: 'Importe a pagar',
        dispatchReturn: 'Despacho devuelto',
        dispatchItemInstitutionalDie: 'Troquel Institucional',
        beneficiaryDocument: 'Beneficiario Documento',
      },
    },
    EXECUTED_ACCOUNTABLE_ITEM: {
      item: 'Elemento contable ejecutado',
      collection: 'Elementos contables ejecutados',
      gender: 'male',
      FIELDS: {
        id: 'Id Elemento Ejecutado',
        dispatchItemId: 'Id Item Despacho',
        dispatchItem: 'Item Despacho',
        executedBillingAmount: 'Importe Facturado',
        executedBillingPercent: 'Porcentaje Facturado',
        executedBillingDocumentId: 'Id Documento de facturación ejecutado',
        executedBillingDocument: 'Documento de facturación ejecutado',
        executedRefundingAmount: 'Monto de reembolso ejecutado',
        executedRefundingPercent: 'Procentaje de reembolso ejecutado',
        executedRefundingDocumentId: 'Id Documento de reembolso ejecutado',
        executedRefundingDocument: 'Documento de reembolso ejecutado',
        executedPharmacyPayment:'Pago de farmacia ejecutado',
        executedPharmacyDocumentId: 'Id Documento de farmacia ejecutado',
        executedPharmacyDocument: 'Documento de farmacia ejecutado',
        executedDrugCompanyPayment: 'Pago de drogueria ejecutado',
        executedDrugCompanyPercent: 'Porcentaje de drogueria ejecutado',
        executedDocumentId: 'Id Documento ejecutado',
        executedDocument: 'Documento ejecutado',
      },
    },
    BENEFICIARY: {
      item: 'Beneficiario',
      collection: 'Beneficiarios',
      gender: 'male',
      FIELDS: {
        documentType: 'Tipo de Documento',
        documentNumber: 'Número de Documento',
        cuil: 'CUIL',
        lastName: 'Apellido',
        firstName: 'Nombre',
        fullName: 'Nombre',
        credentialNumber: 'Número de Credencial',
        financier: 'Financiador',
        birthdate: 'Fecha de Nacimiento',
        gender: 'Sexo',
        plan: 'Plan',
        address: 'Domicilio',
        shippingAddress: 'Domicilio de Envío',
        phone: 'Teléfono',
        email: 'Email',
        active: 'Activo',
        diabetes: 'Diabético',
        disability: 'Discapacitado',
        chronic: 'Crónico',
        notUpgradeable: 'No actualiza padrón',
        contacts: 'Contactos',
        coveragePrograms: 'Programas de Cobertura',
        pathologies: 'Patologías',
        diabetesCertificates: 'Certificados de Diabetes',
        disabilityCertificates: 'Certificados de Discapacidad',
        diagnosisSummaries: 'Resúmenes de Diagnóstico',
        inactivationDate: 'Fecha de Inactivación',
        GENDER: {
          Male: 'Masculino',
          Female: 'Femenino',
        },
        DOCUMENT_TYPES: {
          DNI: 'DNI',
          CUIL: 'CUIL',
          CI: 'CI',
          LC: 'LC',
          LE: 'LE',
        },
      },
      DISPLAY: {
        credential: 'Credencial',
      },
      COVERAGE_PROGRAM: {
        item: 'Programa de Cobertura',
        collection: 'Programas de Cobertura',
        gender: 'male',
        FIELDS: {
          coverageProgram: 'Programa',
          startDate: 'Fecha Inicio',
          endDate: 'Fecha Fin',
          coverage: 'Cobertura %',
          shippingPharmacy: 'Farmacia de Envío',
          pathology: 'Patología',
        },
      },
      DIAGNOSIS_SUMMARY: {
        item: 'Resumen de Diagnóstico',
        collection: 'Resúmenes de Diagnóstico',
        gender: 'male',
        FIELDS: {
          summary: 'Resumen',
          date: 'Fecha',
        },
      },
      CONTACT: {
        item: 'Contacto',
        collection: 'Contactos',
        gender: 'male',
        FIELDS: {
          name: 'Nombre',
          label: 'Etiqueta',
          phone: 'Teléfono',
          email: 'Email',
        },
      },
      PATHOLOGY: {
        item: 'Patología',
        collection: 'Patologías',
        gender: 'female',
        FIELDS: {
          pathology: 'Patología',
          diagnosis: 'Diagnóstico',
        },
      },
      DISABILITY_CERTIFICATE: {
        item: 'Certificado de Discapacidad',
        collection: 'Certificados de Discapacidad',
        gender: 'male',
        FIELDS: {
          title: 'Descripción',
          startDate: 'Fecha Inicio',
          endDate: 'Fecha Fin',
        },
      },
    },
    BENEFICIARY_DIABETES_CERTIFICATE: {
      item: 'Certificado de Diabetes',
      collection: 'Certificados de Diabetes',
      gender: 'male',
      FIELDS: {
        createdAt: 'Creación',
        beneficiary: 'Beneficiario',
        type: 'Tipo de diabetes',
        certificateDate: 'Fecha de Certificado',
        age: 'Edad en la que fue diagnosticado',
        dysplemia: 'Displemia',
        obesity: 'Obesidad',
        smoking: 'Fumador',
        ventricularHypertrophy: 'Hipertrofia Ventricular',
        ventricularHypertrophyDate: 'Fecha de Hipertrofia',
        myocardialInfarction: 'Infarto de Miocardio',
        myocardialInfarctionDate: 'Fecha de Infarto de Miocardio',
        heartFailure: 'Insuficiencia Cardíaca',
        heartFailureDate: 'Fecha de Insuficiencia Cardíaca',
        CVA: 'ACV',
        CVADate: 'Fecha de ACV',
        retinopathy: 'Retinopatía',
        retinopathyDate: 'Fecha de Retinopatía',
        blindness: 'Ceguera',
        blindnessDate: 'Fecha de ceguera',
        peripheralNeuropathy: 'Neuropatía Periférica',
        peripheralNeuropathyDate: 'Fecha Neuropatía Periférica',
        PVD: 'Vasculopatía Periférica',
        PVDDate: 'Fecha de Vasculopatia Periférica',
        amputation: 'Amputación',
        amputationDate: 'Fecha de Amputación',
        kidneyDisease: 'Nefropatía',
        kidneyDiseaseDate: 'Fecha de Nefropatía',
        dialysis: 'Diálisis',
        dialysisDate: 'Fecha de Diálisis',
        kidneyTransplant: 'Transplante Renal',
        kidneyTransplantDate: 'Fecha de Transplante Renal',
        bloodGlucose: 'Valor de Glucemia en ayunas (mg/dL)',
        bloodGlucoseDate: 'Fecha de laboratorio de Glucemia',
        HBA1C: 'Valor de Hemoglobina Glicosilada HBA1C',
        HBA1CDate: 'Fecha de laboratorio de HB1AC',
        LDLColesterol: 'Valor de Colesterol LDL (mg/100ml)',
        LDLColesterolDate: 'Fecha de laboratorio de LDL',
        triglycerides: 'Valor de Triglicéridos',
        triglyceridesDate: 'Fecha de laboratorio de Triglicéridos',
        albuminToCreatinineRatio: 'Microalbuminuria (mcg/min)',
        albuminToCreatinineRatioDate:
          'Fecha de laboratorio de Microalbuminuria',
        systolicBP: 'Presión Arterial Sistólica (mmHg)',
        systolicBPDate: 'Fecha de ultima medición',
        diastolicBP: 'Presión Arterial Diastólica (mmHg)',
        diastolicBPDate: 'Fecha de medición',
        serumCreatinine: 'Valor de Creatinina Sérica',
        serumCreatinineDate: 'Fecha de laboratorio de Creatinina Sérica',
        funduscopy: 'Fondo de ojo',
        funduscopyDate: 'Fecha estudio de Fondo de Ojo',
        weight: 'Peso (kg)',
        weightDate: 'Fecha de medición',
        height: 'Altura (m)',
        heightDate: 'Fecha de medición',
        waistCircumference: 'Circunferencia de cintura',
        waistCircumferenceDate: 'Fecha de medición',
        SMBG: 'Realiza Automonitoreo de Glucemia',
        physicalActivity: 'Realiza Actividad Física regularmente',
        treatmentAdherence: 'Cumple con tratamiento prescripto',
        antihypertensives: 'Medicación Anti Hipertensiva',
        lipidloweringMedication: 'Medicación Hipolipemiante',
        acetylsalicylicAcid: 'Ácido Acetil Salicílico',
        hypoglycemicMedication: 'Hipoglucemiantes Orales',
        basalInsulineType: 'Insulina Basal',
        correctionInsulineType: 'Corrección de insulina',
        TYPE: {
          'Type I': 'Diabetes tipo I',
          'Type II': 'Diabetes tipo II',
          'Gestational Diabetes' : 'Diabetes Gestacional',
          'Labile Diabetes' : 'Diabetes Labil'
        },
        ALBUMIN_TO_CREATININE_RATIO: {
          'No data': 'Sin datos',
          Normal: 'Normal',
          Patologic: 'Patológica',
        },
        FUNDUSCOPY: {
          'No data': 'Sin datos',
          Normal: 'Normal',
          'Diabetic Retinopathy': 'Retinopatía Diabética',
        },
      },
    },
    BENEFICIARY_TREATMENT: {
      item: 'Tratamiento',
      collection: 'Tratamientos',
      gender: 'male',
      FIELDS: {
        beneficiary: 'Beneficiario',
        financierCoverageProgram: 'Financiador - Programa',
        coverageProgram: 'Programa',
        monodrug: 'Monodroga',
        medication: 'Medicamento',
        dosage: 'Dosis Diaria',
        potency: 'Potencia',
        startDate: 'Fecha Desde',
        endDate: 'Fecha Hasta',
        prescribedAmount: 'Unidades Prescritas',
        authorizedAmount: 'Unidades Autorizadas',
        prescribesBrand: 'Prescribe Marca',
        prescribesBrandReason: 'Justificar Prescribe Marca',
        prescribedBrandOnly: 'No admite reemplazo de Marca',
        status: 'Estado',
        observations: 'Observaciones',
        frequency: 'Frecuencia (Días)',
        diagnosis: 'Diagnóstico',
        diagnosisEntity: 'Diagnóstico',
        authorizedUser: 'Autoriza',
        specialCoverage: 'Cobertura Especial',
        coverage: 'Cobertura',
        treatmentMedications: 'Productos',
        requestedAmountCalculated: 'Cantidad Pedida',
        consumedAmountCalculated: 'Cantidad Consumida',
        STATUS: {
          Pending: 'Pendiente',
          Authorized: 'Autorizado',
          Rejected: 'Rechazado',
          Informed: 'Informado',
        },
        ERRORS: {
          treatmentExist:
            'Existe tratamiento para la monodroga y potencia seleccionada',
        },
      },
      TREATMENT_MEDICATION: {
        item: 'Producto',
        collection: 'Productos',
        gender: 'male',
        FIELDS: {
          medication: 'Producto',
          prescribesBrand: 'Prescribe Marca',
          presentation: 'Presentación',
          units: 'Unidades',
          laboratory: 'Laboratorio',
          pvp: 'PVP',
          quotedPrice: 'Precio cotizado',
        },
      },
      createTreatment: 'Crear tratamiento',
    },
    COMMERCIAL_AGREEMENT: {
      item: 'Convenio Comercial',
      collection: 'Convenios Comerciales',
      gender: 'male',
      FIELDS: {
        name: 'Convenio',
      },
    },
    COUNTRY: {
      item: 'País',
      collection: 'Países',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
      },
    },
    COVERAGE_PROGRAM: {
      item: 'Programa de Cobertura',
      collection: 'Programas de Cobertura',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        coverage: 'Cobertura %',
        acute: 'Ambulatorio',
        isDisabilityProgram: 'Discapacidad',
        isPMI: 'Plan Materno Infantil',
        isChronic: 'Crónico',
        isDiabetes: 'Diabetes',
        isShippingDrugCompany: 'Envíos droguería',
        vademecums: 'Vademecums',
        financiers: 'Financiadores'
      },
      VADEMECUM: {
        item: 'Vademecum',
        collection: 'Vademecums',
        gender: 'male',
        FIELDS: {
          vademecum: 'Vademecum',
        },
      },
      FINANCIER: {
        item: 'Financier',
        collection: 'Financiers',
        gender: 'male',
        FIELDS: {
          financier: 'Financier'
        }
      }
    },
    DEBIT_CREDIT_REASONS: {
      item: 'Motivo Debito',
      collection: 'Motivos Debitos',
      gender: 'male',
      FIELDS: {
        description: 'Descripcion',
        type: 'Tipo',
        rebillable: 'Refacturable',
        TYPE: {
          Debito: 'Debito',
          Credito: 'Credito',
        },
        REBILLABLE: {
          Si: 'Si',
          No: 'No',
        },
      },
    },
    DIAGNOSIS: {
      item: 'Diagnóstico',
      collection: 'Diagnósticos',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
        codeMisValidaciones: 'Codigo Mis Validaciones',
        pathology: 'Patología',
      },
    },
    DISPATCH: {
      item: 'Despacho',
      collection: 'Despacho',
      gender: 'male',
      FIELDS: {
        id: 'Id Despacho',
        pharmacyBatchId: 'Id Lote',
        orderNumber: 'Número de Orden',
        date: 'Fecha Venta',
        pharmacy: 'Farmacia',
        prescription: 'Prescripción',
        prescriptionDate: 'Fecha Prescripción',
        prescriptionDoctor: 'Médico Prescriptor',
        prescriptionDiagnosis: 'Diagnóstico',
        beneficiary: 'Beneficiario',
        status: 'Estado',
        validationCode: 'Código Validación',
        presentationCode: 'Código Presentación',
        prescriptionNumber: 'Número Prescripción',
        invoiceType: 'Tipo Comprobante',
        invoiceNumber: 'Nú\u200Bmero Comprobante',
        adjustments: 'Ajustes',
        observations: 'Observaciones',
        return: 'Devolver',
        returnReason: 'Motivo Devolución',
        items: 'Items Despacho',
        STATUS: {
          Dispatched: 'Despachado',
          Audited: 'Auditado',
          Canceled: 'Cancelado',
        },
        documentsReceptionDate: 'Fecha de entrega del Remito',
        documentsDeliveryNoteNumber: 'Número de Remito',
        documentsHaveDeliveryNote: 'Remito Conformado',
        documentsHaveTraceabilityProof: 'Constancia de trazabilidad',
        documentsHaveOriginalPrescription: 'Receta Original',
        documentsHaveDies: 'Troqueles',
        documentsAreComplete: 'Completo',
        documentsObservations: 'Observaciones Documentos',
        prescriptionItemsReceiptNumber:'Remito',
        prescriptionItemsDistributionOrderId: 'Id Orden',
        dispatchTotalPrice: 'PVP Total'
      },
      ADJUSTMENT: {
        item: 'Ajuste',
        collection: 'Ajustes',
        gender: 'male',
        FIELDS: {
          debitCreditReasonId: 'Id',
          debitCreditReason: 'Motivo Debito/Credito',
          reason: 'Motivo',
          amount: 'Importe',
        },
      },
    },
    DISPATCH_ITEM: {
      item: 'Item Despacho',
      collection: 'Items Despacho',
      gender: 'male',
      FIELDS: {
        id: 'Id ítem despacho',
        debitCreditReasonId: 'Id Motivo Debito',
        debitCreditReason: 'Motivo Debito',
        dispatch: 'Despacho',
        prescriptionItem: 'Item Prescripción',
        medication: 'Medicamento',
        monodrug: 'Monodroga',
        pharmacyBatch: 'Lote Farmacia',
        status: 'Estado',
        financierCoverage: '% Cobertura',
        beneficiaryCoverage: '% Afiliado',
        financierAndBeneficiaryCoverage: '% Cobertura % Copago',
        liquidateMethodAndLiquidateValue:
          'Método Liquidación/Valor Liquidación',
        billedPercent: '% a Facturar',
        amount: 'Cantidad',
        unitPrice: 'Precio Unitario',
        totalPrice: 'Total PVP',
        paymentMethod: 'Modalidad de Pago',
        paymentDeadline: 'Plazo de Pago',
        paymentPriority: 'Prioridad de Pago',
        liquidateMethod: 'Método Liquidación',
        liquidateValue: 'Valor Indice',
        liquidateAmount: 'Valor Importe',
        prescribesBrand: 'Prescribe Marca',
        prescribedBrandOnly: 'No admite reemplazo de Marca',
        institutionalDie: 'Troquel anulado',
        prolongedTreatment: 'Tratamiento Prolongado',
        authorizedDispatch: 'Entrega autorizada o salvada',
        adjustment: 'Ajuste',
        debit: 'Débitos',
        debitReason: 'Motivo Débitos',
        credit: 'Créditos',
        creditReason: 'Motivo Créditos',
        coverageAmount: 'Importe Cobertura',
        beneficiaryAmount: 'Importe Afiliado',
        billedAmount: 'Importe a Facturar',
        paymentTotal: 'Total a Pagar',
        financierTotal: 'Total a Cargo Financiador',
        beneficiaryTotal: 'Total a Cargo Beneficiario',
        presentedFinancierCoverage: '% Cobertura presentado',
        presentedBeneficiaryCoverage: '% Afiliado presentado',
        presentedBilledPercent: '% a Facturar presentado',
        presentedAmount: 'Cantidad presentada',
        presentedUnitPrice: 'Precio Unitario presentado',
        presentedTotalPrice: 'Total PVP presentado',
        presentedPaymentTotal: 'Total a Pagar presentado',
        presentedFinancierTotal: 'Total a Cargo Financiador presentado',
        presentedBeneficiaryTotal: 'Total a Cargo Beneficiario presentado',
        auditedFinancierCoverage: '% Cobertura auditado',
        auditedBeneficiaryCoverage: '% Afiliado auditado',
        auditedBilledPercent: '% a Facturar auditado',
        auditedAmount: 'Cantidad auditada',
        auditedUnitPrice: 'Precio Unitario auditado',
        auditedTotalPrice: 'Total PVP auditado',
        auditedPaymentTotal: 'Total a Pagar auditado',
        auditedFinancierTotal: 'Total a Cargo Financiador auditado',
        auditedBeneficiaryTotal: 'Total a Cargo Beneficiario auditado',
        paybackEntityPriceListPercent: 'Aporte',
        date: 'Fecha Venta',
        PAYMENT_METHOD: {
          Transfer: 'Transferencia',
          DrugCompany: 'Droguería',
          Check: 'Cheque',
        },
        PAYMENT_DEADLINE: {
          Deadline0: '0',
          Deadline21: '21',
          Deadline30: '30',
          Deadline45: '45',
          Deadline60: '60',
          Deadline90: '90',
        },
        PAYMENT_PRIORITY: {
          NonSpecified: 'No especificado',
          Week1: 'Semana 1',
          Week2: 'Semana 2',
          Week3: 'Semana 3',
          Week4: 'Semana 4',
        },
        LIQUIDATE_METHOD: {
          MerchandisePercent: 'Mercadería Porcentaje',
          ServiceFixedAmount: 'Servicio Monto Fijo',
          ServicePercent: 'Servicio Porcentaje',
        },
      },
    },
    DISTRIBUTION_TYPE: {
      item: 'Tipo de Distribución',
      collection: 'Tipos de Distribución',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        distance: 'Distancia',
      },
    },
    DOCTOR: {
      item: 'Médico',
      collection: 'Médicos',
      gender: 'male',
      FIELDS: {
        documentType: 'Tipo de Documento',
        documentNumber: 'Número de Documento',
        lastName: 'Apellido',
        firstName: 'Nombre',
        fullName: 'Nombre',
        phone: 'Teléfono',
        email: 'Email',
        active: 'Activo',
        sisaValidationDate: 'Fecha Validación SISA',
        sisaUpdateDate: 'Fecha Vigencia SISA',
        refesCode: 'Código REFES',
        license: 'Nro de licencia',
        licenses: 'Licencias',
        jurisdiction: 'Jurisdicción',
        medicalSpecialities: 'Especialidades',
        DOCUMENT_TYPES: {
          DNI: 'DNI',
          CUIL: 'CUIL',
          CI: 'CI',
          LC: 'LC',
          LE: 'LE',
        },
      },
      DISPLAY: {
        documentTypeAndNumber: 'Documento',
        licenseNumber: 'Número Matrícula',
        jurisdiction: 'Jurisdicción',
      },
      LICENSE: {
        item: 'Licencia',
        collection: 'Licencias',
        gender: 'female',
        FIELDS: {
          license: 'N\u200Búmero Matrícula',
          jurisdiction: 'Jurisdicción',
        },
      },
      MEDICAL_SPECIALITY: {
        item: 'Especialidad',
        collection: 'Especialidades',
        gender: 'female',
        FIELDS: {
          medicalSpeciality: 'Especialidad',
        },
      },
    },
    DOSAGE_FORM: {
      item: 'Forma Farmacéutica',
      collection: 'Formas Farmacéuticas',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
      },
    },
    DRUG_COMPANY: {
      item: 'Droguería',
      collection: 'Droguerías',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        processType: 'Canal de comunicación',
        businessTypes: 'Tipos de Negocio',
        PROCESS_TYPE: {
          'Drg FNX': 'Drg FNX',
          'Drg DDS': 'Drg DDS',
        },
      },
      BUSINESS_TYPE: {
        item: 'Tipo de Negocio',
        collection: 'Tipos de Negocio',
        gender: 'male',
        FIELDS: {
          name: 'Nombre',
          clientCode: 'Código Cliente en Droguería',
          versionCode: 'Código Versión',
          logisticOperatorId: 'Id Operador Logístico',
          logisticOperator: 'Operador Logístico',
          interfaceModel: 'Modelo Interfaz',
          INTERFACE_MODEL : {
            jsonWithText : "Json con texto",
            jsonWithDeliveryNote : "Json con remito",
          },
        }
      },
    },
    COMPANY: {
      item: 'Empresa',
      collection: 'Empresas',
      gender: 'female',
      FIELDS: {
        businessName: 'Razón Social',
        cuit: 'CUIT',
        businessUnit: 'Unidad de Negocio',
        pharmacyPaymentAccount: 'Cuenta Pagos Farmacias',
        drugCompanyPaymentAccount: 'Cuenta Pagos Droguerias',
      },
    },
    FILE: {
      item: 'Archivo',
      collection: 'Archivos',
      Logo: 'Logo',
      LogoUniqueError: 'Solo se puede incluir un logo. Elimine el actual y luego cargue otro.',
      LogoMaxSizeError:'El límite de tamaño para logos es 5MB.',
      LogoTypeError:'Solo se pueden incluir logos en formato PNG.',
      gender: 'male',
      FIELDS: {
        fileType: 'Tipo',
        fileName: 'Nombre',
        uploadedBy: 'Usuario',
        reportFileType: 'Tipo de Reporte',
        fileSize: 'Tamaño',
      },
      DOCUMENT_TYPES: {
        OTHERS: 'Otros',
        LAB_STUDIES: 'Estudios de laboratorio',
        IMAGE_STUDIES: 'Estudios de imágenes',
        HISTORY_SUMMARY: 'Resumen Historial',
        AUTHORIZATION: 'Autorización',
        INFORMED_CONSENT: 'Consentimiento informado',
        DISABILITY_FORM: 'Formulario Discapacidad',
        REGISTRATION_FORM: 'Formulario de Empadronamiento',
        BUDGET: 'Presupuesto',
        RECEIPT: 'Comprobante',
        PRESCRIPTION: 'Receta',
        OTHER_STUDIES: 'Otros estudios complementarios'
      }
    },
    FINANCIER: {
      item: 'Financiador',
      collection: 'Financiadores',
      gender: 'male',
      FIELDS: {
        cuit: 'CUIT',
        businessName: 'Razón Social',
        shortName: 'Nombre Corto',
        address: 'Domicilio',
        phone: 'Teléfono',
        email: 'Email',
        emailReplyAddress: 'Email de Respuesta',
        active: 'Activo',
        automaticAudit: 'Auditoría automática',
        automaticAuthorize: 'Autorización automática',
        authorizeByTreatment: 'Autorización por tratamiento',
        showCommercialBrand: 'Mostrar marca comercial al Prescribir',
        separatePaymentOrderGeneration: 'Generar Liquidación por separado',
        prolongedTreatment: 'Tratamiento prolongado',
        informERP: 'Informa a ERP',
        informMisValidaciones: 'Informa a Mis Validaciones',
        company: 'Empresa',
        logo: 'Logo',
        codeRNOS: 'RNOS',
        codeFarmalink: 'Farmalink',
        codeAdesfa: 'Adesfa',
        codeERP: 'ERP',
        codeMisValidaciones: 'Mis Validaciones',
        userMisValidaciones: 'Usuario Mis Validaciones',
        passwordMisValidaciones: 'Clave Mis Validaciones',
        agreementRules: 'Normas Operativas',
        billingRules: 'Norma Facturación',
        drugCompanies: 'Norma Droguerías',
        paybackEntities: 'Norma Recupero Industria',
        certifarmaCode: 'Código Certifarma',
        disabled: 'Baja',
        observations: 'Observaciones',
      },
      DRUG_COMPANY_RULE: {
        item: 'Norma Droguería',
        collection: 'Norma Droguerías',
        gender: 'female',
        FIELDS: {
          drugCompany: 'Droguería',
          zone: 'Zona Geográfica',
          vademecum: 'Vademecum',
          pharmacyGroup: 'Entidad Farmacéutica',
          listPercent: '% Lista Precio',
        },
      },
      AGREEMENT_RULE: {
        item: 'Norma Operativa',
        collection: 'Norma Operativa',
        gender: 'female',
        FIELDS: {
          coverageProgram: 'Programa de Cobertura',
          commercialAgreement: 'Convenio Comercial',
          treatment: 'Tratamiento',
          validityDays: 'Duración',
          days: 'Frecuencia',
          distributionType: 'Tipo de Distribución',
          financierPercent: '% Cobertura',
          codeMisValidaciones: 'Id Plan Mis Validaciones',
          automaticAudit: 'Auditoria Automática',
          automaticAuthorization: 'Autorizacion Automática',
          prolongedTreatment: 'Tratamiento prolongado',
          prescriptionMaxAmount: 'Tope Cajas por Prescripción',
          itemMaxAmount: 'Tope Cajas por Item',
          prescriptionValidityDays: 'Días Vigencia Prescripción',
          maxPrice: 'Tope Precio',
          periodMaxAmount: 'Tope Cajas Periodo',
          period: 'Periodo',
          requiresApprovedBudget: 'Requiere Cotización',
          mandatoryValidation: 'Validación Obligatoria',
          mixedValidation: 'Validación Mixta',
          dayAnticipation: 'Dias Anticipación',
          diagnosis: 'Diagnóstico'
        },
      },
      BILLING_RULE: {
        item: 'Norma Facturación',
        collection: 'Norma Facturación',
        gender: 'female',
        FIELDS: {
          financier: 'Financiador',
          commercialAgreement: 'Convenio Comercial',
          vademecum: 'Vademecum',
          distributionType: 'Tipo de Distribución',
          costCenter: 'Centro de Costo',
          contractType: 'Tipo de Contrato',
          billingPriceState: 'Estado a Facturar',
          clientERPCode: 'Codigo ERP Cliente',
          agreementCodeERP: 'Código ERP Convenio',
          zone: 'Zona Geografica',
          DISTRIBUTION_TYPE: {
            DrugCompany: 'Droguería',
            Dispensation: 'Dispensación',
          },
          CONTRACT_TYPE: {
            Capita: 'Cápita',
            Provision: 'Prestación',
          },
          BILLING_PRICE_STATE: {
            Loaded: 'Cargado',
            Authorized: 'Autorizado',
            Rejected: 'Rechazado',
            Audited: 'Auditado',
            AutomaticallyAudited: 'Auditado Automático',
            Requested: 'Pedido',
            Shipped: 'Enviado',
            InPharmacy: 'En Farmacia',
            PartiallyDispensed: 'Entregado Parcial',
            Dispensed: 'Entregado',
            Returned: 'Devuelto',
            Canceled: 'Cancelado',
          },
        },
      },
      PAYBACK_ENTITY: {
        item: 'Recupero Industria',
        collection: 'Recupero Industria',
        gender: 'female',
        FIELDS: {
          financier: 'Financiador',
          vademecum: 'Vademecum',
          paybackEntity: 'Entidad Recupero',
          priceListPercent: '% Lista Precio',
        },
      },
    },
    GROUP: {
      item: 'Rol',
      collection: 'Roles',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        description: 'Descripción',
        reserved: 'Reservado',
      },
      DRUG_COMPANY: {
        item: 'Droguería',
        collection: 'Droguerías',
        gender: 'female',
      },
      FINANCIER: {
        item: 'Financiador',
        collection: 'Financiadores',
        gender: 'male',
      },
      ROLE: {
        item: 'Rol',
        collection: 'Roles',
        gender: 'male',
      },
      TEMPLATE: {
        item: 'Reporte',
        collection: 'Reportes',
        gender: 'male',
      },
    },
    INCIDENT: {
      title: 'Incidencias',
      item: 'Incidencia',
      collection: 'Incidencias',
      gender: 'female',
      missingValuesNewIncidentError: 'Algunos campos obligatorios no estan completos',
      FIELDS: {
        status: 'Estado',
        applicantType: 'Tipo de Solicitante',
        applicantDisplay: 'Solicitante',
        applicantId: 'Id. Solicitante',
        applicant: 'Solicitante',
        user: 'Personal Asignado',
        createdByUser: 'Usuario creador',
        incidentArea: 'Area de Derivación',
        prescription: 'Prescripción',
        incidentReason: 'Motivo/Causa',
        type: 'Tipo',
        priority: 'Prioridad',
        subject: 'Asunto',
        description: 'Descripción',
        INCIDENT_TYPE: {
          Question: 'Pregunta',
          Incident: 'Incidencia',
          Problem: 'Problema',
          Task: 'Tarea',
        },
        INCIDENT_STATUS: {
          New: 'Nueva',
          Open: 'Abierta',
          Pending: 'Pendiente',
          Resolved: 'Resuelta',
          List: 'Listar',
        },
        APPLICANT_TYPE: {
          User: 'Usuario',
          Financier: 'Financiador',
          Pharmacy: 'Farmacia',
          Doctor: 'Médico',
          Beneficiary: 'Beneficiario',
        },
        PRIORITY: {
          Low: 'Baja',
          Normal: 'Normal',
          High: 'Alta',
          Urgent: 'Urgente',
        },
        origin: 'Origen',
        ORIGIN: {
          App: 'App',
          Web: 'Web',
        },
        formDate: 'Fecha del Formulario',
        prescriptionDate: 'Fecha de Prescripción',
        messageBeneficiary: 'Mensajes al Beneficiario',
      },
    },
    INCIDENT_AREA: {
      item: 'Area de Derivación',
      collection: 'Areas de Derivación',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
      },
    },
    INCIDENT_REASON: {
      item: 'Razón de Incidencia',
      collection: 'Razones de Incidencia',
      gender: 'female',
      FIELDS: {
        reason: 'Razón',
      },
    },
    INSULIN_TYPE: {
      item: 'Insulina',
      collection: 'Insulinas',
      gender: 'male',
      FIELDS: {
        code: 'Código',
        genericName: 'Nombre Genérico',
        commercialName: 'Nombre Comercial',
      },
    },
    INVOICE_TYPE: {
      item: 'Tipo de Comprobante',
      collection: 'Tipos de Comprobante',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
      },
    },
    LABORATORY: {
      item: 'Laboratorio',
      collection: 'Laboratorios',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
      },
    },
    LIQUIDATION: {
      item: 'Liquidación',
      collection: 'Liquidaciones',
      gender: 'female',
      exportAmerica: 'Exportar a America',
      processed: 'Procesadas',
      genereted: 'Generadas',
      informed: 'Informadas',
      paid: 'Pagadas',
      print_Liquidations: 'Imprimir Liquidaciones',
      print_Batches: 'Imprimir Caratula de Lotes',
      CONFIRM_INFORMED: {
        title: 'Liquidación informada',
        message: 'La liquidación fue informada para su pago, ¿Desea volver al estado anterior?',
      },
      CONFIRM_PAID: {
        title: 'Liquidación pagada',
        message: 'La Liquidación se encuentra Abonada, ¿Desea volver al estado anterior?',
      },
      FIELDS: {
        pharmacy: 'Farmacia',
        status: 'Estado',
        pharmacyGroup: 'Entidad Farmacéutica',
        invoiceType: 'Tipo Comprobante',
        invoiceNumber: 'Número Comprobante',
        invoiceDate: 'Fecha Comprobante',
        invoiceAmount: 'Importe Comprobante',
        receptionDate: 'Fecha Recepción',
        liquidatedAmount: 'Importe Liquidado',
        debitAmount: 'Importe Debito',
        debitedAmount: 'Importe Debitado',
        items: 'Lotes',
        period: 'Periodo',
        liquidatedPVPService: 'PVP Total',
        paymentMethod: 'Metodo de Pago',
        expirationDate: 'Fecha Vencimiento',
        STATUS: {
          Generated: 'Generado',
          Processed: 'Procesado',
          Informed: 'Informado',
          Paid: 'Pagado',
        },
        asociatedNetwork: 'Red Asociada',
        adjustments: 'Ajustes',
        observations: 'Observaciones',
      },
      ADJUSTMENT: {
        item: 'Ajuste',
        collection: 'Ajustes',
        gender: 'male',
        FIELDS: {
          debitCreditReasonId: 'Id',
          debitCreditReason: 'Motivo Debito/Credito',
          detail: 'Detalle',
          amount: 'Importe',
        }
      }
    },
    LIQUIDATION_LOG: {
      item: 'Log Liquidación',
      collection: 'Logs Liquidación',
      gender: 'male',
      FIELDS: {
        liquidationId: 'Id. Liquidación',
        activityType: 'Estado',
        operation: 'Operación',
        userId: 'Id Usuario',
        user: 'Usuario',
        observations: 'Observaciones',
        createdAt: 'Fecha',
      },
    },
    LOCALITY: {
      item: 'Localidad',
      collection: 'Localidades',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        region: 'Región',
        country: 'País',
        codeERP: 'Código ERP',
      },
    },
    LOGISTIC_OPERATOR: {
      item: 'Operador Logístico',
      collection: 'Operadores Logísticos',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        clientNumber: 'Número de Cliente',
        clientUser: 'Nombre de Usuario',
        clientPassword: 'Contraseña',
      },
    },
    MATERIAL_TYPE: {
      item: 'Tipo de Material',
      collection: 'Tipos de Material',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
      },
    },
    MEDICAL_SPECIALITY: {
      item: 'Especialidad',
      collection: 'Especialidades',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
      },
    },
    MEDICATION: {
      item: 'Medicamento',
      collection: 'Medicamentos',
      gender: 'male',
      FIELDS: {
        registerNumber: 'Número Registro',
        dieCode: 'Troquel',
        name: 'Nombre',
        presentation: 'Presentación',
        potency: 'Potencia',
        laboratory: 'Laboratorio',
        price: 'Precio',
        date: 'Fecha Vigencia Precio',
        barcode: 'Código de Barra',
        unitsALF: 'Unidades ALF',
        unitsINT: 'Unidades INT',
        pharmacologicalAction: 'Acción Farmacológica',
        monodrug: 'Monodroga',
        dosageForm: 'Forma Farmacéutica',
        potencyUnit: 'Unidad de Potencia',
        unitType: 'Tipo de Unidad',
        administrationRoute: 'Vía de Administración',
        materialType: 'Tipo de Material',
        codeATC: 'Código ATC',
        treatmentDay: 'Dias Tratamiento',
        r310: 'R310',
        r310financierAmount: 'R310: Importe financiador',
        r310minimumBeneficiaryAmount: 'R310: Importe mínimo beneficiario',
        refrigerator: 'Require Frío',
        industryContribution: 'Recupero Industria',
        inactive: 'Baja',
        disabled: 'Inhabilitado',
        traceable: 'Trazable',
        drugCompanies: 'Droguería Alias',
        priceHistory: 'Histórico de Precios',
        iva: 'IVA',
        compassionateUse: 'Uso Compasivo',
        narcotic: 'Narcótico',
        controlledProduct: 'Producto Controlado',
        CONTROLLED_PRODUCT_TYPE: {
          No_controlado: 'No controlado',
          Psicotrópico_II: 'Psicotrópico II',
          Psicotrópico_III: 'Psicotrópico III',
          Psicotrópico_IV: 'Psicotrópico IV',
          Estupefaciente_I: 'Estupefaciente I',
          Estupefaciente_II:'Estupefaciente II',
          Estupefaciente_III: 'Estupefaciente III',
          Succinilcolina: 'Succinilcolina',
          Venta_Vigilada: 'Venta Vigilada',
        },
      },
      DRUG_COMPANY_ALIAS: {
        item: 'Medicamento Droguería Alias',
        collection: 'Medicamento Droguería Alias',
        gender: 'male',
        FIELDS: {
          drugCompany: 'Droguería',
          barcodeType: 'Tipo Cod. Barra',
          barcode: 'Cod. Barra Drg',
          SAPCode: 'Id Articulo SAP',
          discount: 'Descuento',
        },
      },
      PRICE_HISTORY: {
        item: 'Precio del Medicamento',
        collection: 'Precios del Medicamento',
        gender: 'male',
        FIELDS: {
          price: 'Precio',
          date: 'Fecha',
        },
      },
    },
    MONODRUG: {
      item: 'Monodroga',
      collection: 'Monodrogas',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
        resolution15612012: 'Resolución 1561/2012',
        tutelageSur: 'Tutelaje SUR',
      },
    },
    PATHOLOGY: {
      item: 'Patología',
      collection: 'Patologías',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
        defaultPercent: 'Porcentaje por Defecto',
      },
    },
    PAYBACK_ENTITY: {
      item: 'Recupero Industria',
      collection: 'Recupero Industria',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
      },
    },
    PHARMACOLOGICAL_ACTION: {
      item: 'Acción Farmacológica',
      collection: 'Acciones Farmacológicas',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
      },
    },
    PHARMACY: {
      item: 'Farmacia',
      collection: 'Farmacias',
      gender: 'female',
      FIELDS: {
        cuit: 'CUIT',
        gln: 'GLN',
        cuf: 'CUF',
        businessName: 'Razón Social',
        shortName: 'Nombre Corto',
        address: 'Domicilio',
        active: 'Activa',
        startDate: 'Fecha Alta',
        endDate: 'Fecha Baja',
        openingHours: 'Horarios de Atención',
        observations: 'Observaciones',
        pharmacyGroup: 'Agrupación',
        debitShippingAddress: 'Domicilio de Envío Débitos',
        open24hs: '24hs',
        homeDelivery: 'Envío a Domicilio',
        redispense: 'Redespacho',
        paymentMethod: 'Modalidad de Pago',
        paymentDeadline: 'Plazo de Pago',
        paymentPriority: 'Prioridad de Pago',
        codeERP: 'ERP',
        paymentEmail: 'Email Pagos',
        codeAdesfa: 'Adesfa',
        codeFarmalink: 'Farmalink',
        codePAMI: 'PAMI',
        codeMisValidaciones: 'Mis Validaciones',
        disabledPreserfar: 'Inhabilitada en Preserfar',
        drugCompanies: 'Droguerías',
        agreementRules: 'Normas Liquidación',
        contacts: 'Contactos',
        drugCompany: 'Drogueria de Pago',
        PAYMENT_METHOD: {
          Transfer: 'Transferencia',
          Check: 'Cheque',
          DrugCompany: 'Droguería',
        },
        PAYMENT_DEADLINE: {
          Deadline0: '0',
          Deadline21: '21',
          Deadline30: '30',
          Deadline45: '45',
          Deadline60: '60',
          Deadline90: '90',
        },
        PAYMENT_PRIORITY: {
          NonSpecified: 'No especificado',
          Week1: 'Semana 1',
          Week2: 'Semana 2',
          Week3: 'Semana 3',
          Week4: 'Semana 4',
        },
      },
      DRUG_COMPANY: {
        item: 'Droguería',
        collection: 'Droguerías',
        gender: 'female',
        FIELDS: {
          drugCompany: 'Droguería',
          clientCode: 'Código Cliente en Droguería',
          branch: 'Sucursal',
          division: 'Delegación',
        },
      },
      AGREEMENT_RULE: {
        item: 'Norma de Liquidación',
        collection: 'Normas de Liquidación',
        gender: 'female',
        FIELDS: {
          financier: 'Financiador',
          coverageProgram: 'Programa de Cobertura',
          financierAndCoverageProgram: 'Financiador / Programa de cobertura',
          vademecum: 'Vademecum',
          distributionType: 'Tipo de Distribución',
          drugCompany: 'Droguería',
          drugCompanyBusinessType: 'Tipo de Negocio',
          liquidateMethod: 'Método Liquidación',
          value: 'Valor',
          byPharmacyGroup: 'Cobro por Entidad Farmacéutica',
          refillOwnStock: 'Entrega del Stock',
          institucionalDie: 'Troquel Institucional',
          pharmacyGroup: 'Entidad Farmacéutica',
          DISTRIBUTION_TYPE: {
            DrugCompany: 'Droguería',
            Dispensation: 'Dispensación',
          },
          LIQUIDATE_METHOD: {
            MerchandisePercent: 'Mercadería Porcentaje',
            ServiceFixedAmount: 'Servicio Monto Fijo',
            ServicePercent: 'Servicio Porcentaje',
          },
        },
      },
      CONTACT: {
        item: 'Contacto',
        collection: 'Contactos',
        gender: 'male',
        FIELDS: {
          name: 'Nombre',
          label: 'Etiqueta',
          phone: 'Teléfono',
          email: 'Email',
        },
      },
    },
    PHARMACY_BATCH: {
      item: 'Lote Farmacia',
      collection: 'Lotes Farmacias',
      gender: 'male',
      generated: 'Generado',
      presented: 'Presentado',
      audited: 'Auditado',
      liquidated: 'Liquidado',
      FIELDS: {
        id: 'Id Lote',
        period: 'Periodo',
        liquidationId: 'Id Liquidacion',
        receptionDate: 'Fecha Recepción',
        pharmacy: 'Farmacia',
        financier: 'Financiador',
        coverageProgram: 'Programa de Cobertura',
        status: 'Estado',
        statusBatch: 'Estado Lote',
        invoiceType: 'Tipo Comprobante',
        invoiceNumber: 'Número Comprobante',
        presentationCode: 'Código Presentación',
        observations: 'Observaciones',
        return: 'Devolver',
        returnReason: 'Motivo Devolución',
        chargeTotal: 'A cargo total',
        dueTotal: 'A pagar total',
        adjustments: 'Ajustes',
        items: 'Despachos asociados',
        presentationDate: 'Fecha Presentación',
        recipes: 'Recetas',
        liquidatedPaymentTotal: 'Total a pagar',
        liquidatedPVPService: 'PVP Total',
        liquidatedTotal: 'PVP Total',
        debitedAmount: 'Debitos',
        liquidatedPrescriptions: 'Recetas',
        STATUS: {
          Generated: 'Generado',
          Presented: 'Presentado',
          Audited: 'Auditado',
          Liquidated: 'Liquidado',
        },
      },
      ADJUSTMENT: {
        item: 'Ajuste',
        collection: 'Ajustes',
        gender: 'male',
        FIELDS: {
          debitCreditReasonId: 'Id',
          debitCreditReason: 'Motivo Debito/Credito',
          reason: 'Motivo',
          amount: 'Importe',
        },
      },
    },
    PHARMACY_BATCH_LOG: {
      item: 'Log Lote Farmacia',
      collection: 'Logs Lote Farmacia',
      gender: 'male',
      FIELDS: {
        pharmacyBatchId: 'Id. Lote Farmacia',
        activityType: 'Estado',
        operation: 'Operación',
        userId: 'Id Usuario',
        user: 'Usuario',
        observations: 'Observaciones',
        createdAt: 'Fecha',
      },
    },
    PHARMACY_GROUP: {
      item: 'Entidad Farmacéutica',
      collection: 'Entidades Farmacéuticas',
      gender: 'female',
      FIELDS: {
        cuit: 'CUIT',
        businessName: 'Nombre',
        observations: 'Observaciones',
        address: 'Domicilio',
        active: 'Activa',
        paymentMethod: 'Modalidad de Pago',
        paymentDeadline: 'Plazo de Pago',
        paymentPriority: 'Prioridad de Pago',
        codeERP: 'ERP',
        paymentEmail: 'Email Pagos',
        bankAccounts: 'Cuentas Bancarias',
        pharmacies: 'Farmacias Asociadas',
        drugCompanies: 'Droguerías',
        contacts: 'Contactos',
        drugCompany: 'Drogueria de Pago',
        centralizeDebits: 'Centralizar debitos',
        PAYMENT_METHOD: {
          Transfer: 'Transferencia',
          Check: 'Cheque',
          DrugCompany: 'Droguería',
        },
        PAYMENT_DEADLINE: {
          Deadline0: '0',
          Deadline21: '21',
          Deadline30: '30',
          Deadline45: '45',
          Deadline60: '60',
          Deadline90: '90',
        },
        PAYMENT_PRIORITY: {
          NonSpecified: 'No especificado',
          Week1: 'Semana 1',
          Week2: 'Semana 2',
          Week3: 'Semana 3',
          Week4: 'Semana 4',
        },
      },
      BANK_ACCOUNT: {
        item: 'Cuenta Bancaria',
        collection: 'Cuentas Bancarias',
        gender: 'female',
        FIELDS: {
          bankName: 'Banco',
          branch: 'Sucursal',
          accountType: 'Tipo cuenta',
          accountNumber: 'Número',
          accountHolder: 'Titulares/CUITs',
          cbu: 'CBU',
          cbuAlias: 'Alias',
        },
      },
      CONTACT: {
        item: 'Contacto',
        collection: 'Contactos',
        gender: 'male',
        FIELDS: {
          name: 'Nombre',
          label: 'Etiqueta',
          phone: 'Teléfono',
          email: 'Email',
        },
      },
      DRUG_COMPANY: {
        item: 'Droguería',
        collection: 'Droguerías',
        gender: 'female',
        FIELDS: {
          drugCompany: 'Droguería',
          clientCode: 'Código Cliente en Droguería',
          branch: 'Sucursal',
          division: 'Delegación',
        },
      },
    },
    POTENCY_UNIT: {
      item: 'Unidad de Potencia',
      collection: 'Unidades de Potencia',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
      },
    },
    PRESCRIPTION: {
      item: 'Prescripción',
      collection: 'Prescripciones',
      gender: 'female',
      observations_list: "Observaciones Prescripción",
      FIELDS: {
        id: 'Id Prescripción',
        createdAt: 'Fecha Carga',
        date: 'Fecha Prescripción',
        saleDate: 'Fecha de Venta',
        provisionLimitDate: 'Fecha Limite Prestación',
        treatmentExpirationDate: 'Fecha Vencimiento Tratamiento',
        status: 'Estado',
        orderNumber: 'Número de Pedido',
        orderDate: 'Fecha de la Orden',
        redispense: 'Redespacho',
        purchaseOrder: 'Orden de compra',
        beneficiary: 'Beneficiario',
        financier: 'Financiador',
        coverageProgram: 'Programa de Cobertura',
        doctor: 'Médico',
        doctorLastName: 'Apellido Médico',
        doctorFirstName: 'Nombre Médico',
        doctorFullName: 'Nombre Médico',
        doctorLicense: 'Número de Matrícula',
        shippingAddress: 'Domicilio de Entrega',
        distributionType: 'Tipo de distribución',
        drugCompany: 'Droguería',
        pharmacy: 'Farmacia',
        indications: 'Indicaciones Médicas',
        observations: 'Observaciones',
        mailNotificationSent: 'Notificación por Email',
        conMedicamentos: 'Con Medicamentos',
        items: 'Medicamentos Prescritos',
        delivery: 'Requiere envío a domicilio',
        STATUS: {
          Loaded: 'Cargado',
          Authorized: 'Autorizado',
          Rejected: 'Rechazado',
          Audited: 'Auditado',
          AutomaticallyAudited: 'Auditado Automático',
          Requested: 'Pedido',
          Shipped: 'Enviado',
          InPharmacy: 'En Farmacia',
          PartiallyDispensed: 'Entregado Parcial',
          Dispensed: 'Entregado',
          Returned: 'Devuelto',
          Canceled: 'Cancelado',
        },
        DISTRIBUTION_TYPE: {
          DrugCompany: 'Droguería',
          Dispensation: 'Dispensación',
        },
      },
      LABELS: {
        sendEmailMessage:
          'Ingrese observación a ser incluida en el mail a la farmacia',
        emailSent: 'Email enviado',
      },
    },
    PRESCRIPTION_ITEM: {
      item: 'Item Prescripción',
      collection: 'Items Prescripción',
      gender: 'male',
      observationAudit: "Observaciones Auditoría",
      FIELDS: {
        id: "Id ítem prescripción",
        prescriptionId: 'Id. Prescripción',
        distributionOrderId: 'Id. Orden',
        distributionOrderDate: 'Fecha Pedido',
        monodrug: 'Monodroga',
        medication: 'Medicamento',
        dosageForm: 'Forma Farmacéutica',
        administrationRoute: 'Via de Administración',
        potency: 'Potencia',
        coverageProgram: 'Programa de Cobertura',
        billingPrice: 'Precio de Venta',
        contractType: 'Tipo de Contrato',
        costCenter: 'Centro de Costo',
        diagnosis: 'Diagnóstico',
        diagnosisEntity: 'Diagnóstico',
        status: 'Estado',
        dosage: 'Dosis',
        period: 'Intervalo',
        traceable: 'Trazable',
        days: 'Días',
        prescribesBrand: 'Prescribe Marca',
        prescribesBrandReason: 'Justificar Prescribe Marca',
        prescribedBrandOnly: 'No admite reemplazo de Marca',
        prescribedAmount: 'Cantidad Prescrita',
        itemCoverage: 'Cobertura',
        authorizedUser: 'Autoriza',
        unitPrescribedAmount: 'Cantidad Unidades Internas',
        authorizedAmount: 'Cantidad Autorizada',
        annualConsumption: 'Consumo Anual',
        issuedAmount: 'Cantidad Entregada',
        deliverAmount: 'Entregar',
        amount: 'Entregada',
        dispatchAmount: 'Cantidad a Despachar',
        financierCoverage: '% Cobertura',
        beneficiaryCoverage: '% Beneficiario',
        beneficiaryUnitPrice: 'Copago',
        billedPercent: '% a Facturar',
        specialCoverage: 'Cobertura Especial',
        misValidacionesPrescriptionId: 'Mis Validaciones Id Prescripción',
        codeMisValidaciones: 'Código Mis Validaciones',
        beneficiary: 'Beneficiario',
        beneficiaryName: 'Nombre Beneficiario',
        beneficiaryCredentialNumber: 'Credencial Beneficiario',
        beneficiaryDocumentNumber: 'Documento Beneficiario',
        nextDeliveryDate: 'Próxima entrega',
        financier: 'Financiador',
        date: 'Fecha prescripción',
        observations: 'Observaciones',
        receiptNumber: 'Remito',
        pharmacyDrugCompanyClientCode: 'Codigo Cliente SAP',
        medicationBarcodeDisplay: 'Codigo de Barra SAP',
        distributionStatus: 'Estado droguería',
        estimatedEntryDate: 'Fecha estimada ingreso',
        STATUS: {
          Loaded: 'Cargado',
          Authorized: 'Autorizado',
          Rejected: 'Rechazado',
          Audited: 'Auditado',
          AutomaticallyAudited: 'Auditado Automático',
          Requested: 'Pedido',
          Shipped: 'Enviado',
          InPharmacy: 'En Farmacia',
          PartiallyDispensed: 'Entregado Parcial',
          Dispensed: 'Entregado',
          Returned: 'Devuelto',
          Canceled: 'Cancelado',
        },
        DISTRIBUTION_STATUS: {
          OutOfStock : 'Sin stock',
          Missing : 'Falta',
          Purchase : 'Compra',
          Transfer : 'Transferencia'
        },
        PERIODS: {
          every6Hours: 'Cada 6 Horas',
          every8Hours: 'Cada 8 Horas',
          every12Hours: 'Cada 12 Horas',
          every24Hours: 'Cada 24 Horas',
          otherDay: 'Interdiario',
          weekly: 'Semanal',
          biweekly: 'Quincenal',
          monthly: 'Mensual',
          bimonthly: 'Bimestral',
          quarterly: 'Trimestral',
          biannual: 'Semestral',
          annual: 'Anual',
        },
        CONTRACT_TYPE: {
          Capita: 'Cápita',
          Provision: 'Prestación',
        },
      },
    },
    PRESCRIPTION_ITEM_LOG: {
      item: 'Log Item Prescripción',
      collection: 'Logs Item Prescripción',
      gender: 'male',
      FIELDS: {
        prescriptionItemId: 'Id. Item Prescripción',
        activityType: 'Estado',
        operation: 'Operación',
        userId: 'Id Usuario',
        user: 'Usuario',
        observations: 'Observaciones',
        createdAt: 'Fecha',
      },
    },
    BENEFICIARY_TREATMENT_LOG: {
      item: 'Log tratamiento',
      collection: 'Logs tratamientos',
      gender: 'male',
      FIELDS: {
        beneficiaryTreatmentId: 'Id. tratamiento',
        activityType: 'Estado',
        operation: 'Operación',
        userId: 'Id Usuario',
        user: 'Usuario',
        observations: 'Observaciones',
        createdAt: 'Fecha',
      },
    },
    REGION: {
      item: 'Region',
      collection: 'Regiones',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
        country: 'País',
        codeERP: 'Código ERP',
        codePreserfar: 'Código Preserfar',
      },
    },
    REPORT_TEMPLATE: {
      item: 'Reporte',
      collection: 'Reportes',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        responseType: 'Formato de reporte',
        view: 'Vista',
        columns: 'Dimensiones',
        filters: 'Filtros',
        RESPONSE_TYPE: {
          PDF: 'PDF',
          CSV: 'CSV',
          HTML: 'HTML',
        },
      },
      FILTER: {
        item: 'Filtro',
        collection: 'Filtros',
        gender: 'male',
        FIELDS: {
          column: 'Dimensión',
          operation: 'Operación',
          value: 'Valor',
        },
        OPERATIONS: {
          greater: 'Mayor',
          greaterOrEqual: 'Mayor o Igual',
          less: 'Menor',
          lessOrEqual: 'Menor o Igual',
          equal: 'Igual',
          different: 'Distinto',
          similar: 'Parecido',
        },
      },
      ERRORS: {
        reportNameExists: 'El nombre del reporte ya existe'
      }
    },

    DISTRIBUTE: {
      MODAL: {
        title: 'Obtener estado del Item Prescripcion',
        description: '¿Desea consultar el estado del item prescripción?',
      }
    },
    SAP_DISTRIBUTION_RULE: {
      item: 'Norma de Reparto SAP',
      collection: 'Normas de Reparto SAP',
      gender: 'female',
      FIELDS: {
        contractType: 'Tipo de Contrato',
        financierPercent: '% Cobertura',
        billedPercent: '% a Facturar',
        code: 'Código',
        CONTRACT_TYPE: {
          Capita: 'Cápita',
          Provision: 'Prestación',
        },
      },
    },
    UNIT_TYPE: {
      item: 'Tipo de Unidad',
      collection: 'Tipos de Unidad',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
        code: 'Código',
      },
    },
    USER: {
      item: 'Usuario',
      collection: 'Usuarios',
      gender: 'male',
      FIELDS: {
        email: 'Email',
        name: 'Nombre',
        description: 'Descripción',
        entityType: 'Tipo de Entidad',
        entityId: 'Id. Entidad Asociada',
        entityDisplay: 'Entidad Asociada',
        status: 'Estado',
        groups: 'Grupos',
        STATUS: {
          Active: 'Activo',
          Inactive: 'Inactivo',
          Blocked: 'Bloqueado',
          Disabled: 'Deshabilitado',
        },
        ENTITY_TYPE: {
          User: 'Personal Interno',
          Financier: 'Financiador',
          Pharmacy: 'Farmacia',
          Doctor: 'Médico',
          Beneficiary: 'Beneficiario',
          DrugCompany: 'Drogueria',
          PharmacyGroup: 'Entidad Farmacéutica'
        },
      },
      GROUP: {
        item: 'Rol',
        collection: 'Roles',
        gender: 'male',
      },
      AUDITRULES: {
        item: 'Regla de auditoria',
        collection: 'Reglas de auditoria',
        gender: 'female',
        FIELDS: {
          financier: 'Financiador',
          coverageProgram: 'Programa de cobertura',
        },
      },
    },
    VADEMECUM: {
      item: 'Vademecum',
      collection: 'Vademecums',
      gender: 'male',
      FIELDS: {
        name: 'Nombre',
      },
    },
    VADEMECUM_ITEM: {
      item: 'Medicamento',
      collection: 'Medicamentos',
      gender: 'male',
      FIELDS: {
        monodrug: 'Monodroga',
        medication: 'Medicamento',
        percent: 'Porcentaje',
        preferentially: 'Preferencial',
        checked: 'Controlado',
      },
    },
    ZONE: {
      item: 'Zona',
      collection: 'Zonas',
      gender: 'female',
      FIELDS: {
        name: 'Nombre',
      },
    },
    ZONE_LOCALITY: {
      item: 'Localidad',
      collection: 'Localidades',
      gender: 'male',
      FIELDS: {
        zone: 'Localidad',
        country: 'País',
        region: 'Región',
        locality: 'Localidad',
      },
    },
  },
  AUTH: {
    LOGIN: {
      title: 'Farmanager',
      username: 'Usuario',
      password: 'Contraseña',
      login: 'Iniciar sesión',
      passwordReset: '¿Olvidaste tu contraseña?',
      recoveryAccount: 'Recuperar cuenta',
      invalidCredentialsError: 'Usuario o contraseña incorrectos',
      inactiveError: 'El usuario no ha sido activado aún',
      blockedError: 'El usuario ha sido bloqueado',
      disabledError: 'El usuario ha sido desactivado',
      passwordExpiredError:
        'Su contraseña ha caducado. Ingresar a ¿Olvidaste tu contraseña? para reestablecerla.',
    },
    PASSWORD_RECOVERY: {
      title: '¿Olvidaste tu contraseña?',
      description: 'Puedes restablecer tu contraseña con tu email',
      email: 'E-mail',
      resetPassword: 'Restablecer Contraseña',
      notFoundError: 'No se ha encontrado una cuenta asociada al email',
      emailSent:
        'Un email ha sido enviado a tu correo con instrucciones para restablecer tu contraseña.',
    },
    ACCOUNT_RECOVERY: {
      title: '¿Se bloqueó tu cuenta?',
      description:
        'Ingresa tu email y te enviaremos un enlace para recuperar tu cuenta',
      email: 'E-mail',
      recoverAccount: 'Recuperar cuenta',
      notFoundError: 'No se ha encontrado una cuenta asociada al email',
      emailSent:
        'Un email ha sido enviado a tu correo con instrucciones para recuperar tu cuenta.',
    },
    PASSWORD_RESET: {
      title: 'Ingresa tu nueva contraseña',
      description: '',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirmar contraseña',
      resetPassword: 'Restablecer Contraseña',
      passwordChanged: 'Tu contraseña ha sido actualizada',
      passwordChangeSameError: 'No se puede usar una contraseña anterior.',
    },
    SETUP_ACCOUNT: {
      title: 'Configura tu cuenta',
      description: 'Debes establecer una contraseña para tu cuenta',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirma contraseña',
      setup: 'Establecer contraseña',
      accountSetUp: 'Tu cuenta ha sido configurada',
      notFoundError: 'No se ha encontrado una cuenta asociada al email',
    },
  },
  HOME: {
    HEADER: {
      logoTitle: 'Farmanager',
      logout: 'Desconectarse',
    },
    PROFILE: {
      title: 'Perfil de usuario',
      passwordUpdate: 'Cambiar contraseña',
      passwordUpdated: 'Contraseña actualizada',
      passwordUpdateError: 'No se pudo actualizar la contraseña',
      oldPassword: 'Contraseña actual',
      newPassword: 'Contraseña nueva',
      confirmPassword: 'Confirma contraseña',
      invalidCredentialsError: 'Contraseña actual incorrecta',
      authorizationError:
        'No está autorizado para realizar la operación solicitada.',
    },
    SIDEBAR: {
      dashboard: 'Dashboard',
      list: 'Listar',
      prescribe: 'Prescribir',
      authorize: 'Autorizar',
      audit: 'Auditar',
      validate: 'Validar',
      distribute: 'Distribuir',
      LIQUIDATE: {
        title: 'Liquidar',
        dispatches: 'Despachos',
        pharmacyBatches: 'Lotes Farmacias',
        liquidations: 'Liquidaciones',
        inverseLogistics: 'Logística Inversa',
        billing: 'Facturación',
        industryRecovery: 'Recupero Industria',
        presentations: 'Presentaciones'
      },
      reports: 'Reportes',
      PROFILE: {
        size: 'Tamaño Interfaz',
        staticMenu: 'Menu estático',
        title: 'Perfil',
        logout: 'Salir',
      },
      SETUP: {
        title: 'Configurar',
        ADMIN: {
          title: 'Administración',
          auditLogs: 'Logs de Auditoría',
          groups: 'Roles',
          users: 'Usuarios',
        },
        OPERATIONS: {
          title: 'Operaciones',
          beneficiaries: 'Beneficiarios',
          financiers: 'Financiadores',
          incidents: 'Incidencias',
          incidentAreas: 'Areas de Derivación',
          incidentReasons: 'Razones de Incidencias',
          MEDICATIONS: {
            title: 'Medicamentos',
            administrationRoutes: 'Vías de Administración',
            alfabeta: 'Alfabeta',
            dosageForms: 'Formas Farmacéuticas',
            laboratories: 'Laboratorios',
            materialTypes: 'Tipos de Material',
            medications: 'Medicamentos',
            monodrugs: 'Monodrogas',
            pharmacologicalActions: 'Acciones Farmacológicas',
            potencyUnits: 'Unidades de Potencia',
            unitTypes: 'Tipos de Unidad',
            vademecums: 'Vademecums',
            PLACES: {
              title: 'Lugares',
              countries: 'Países',
              localities: 'Localidades',
              regions: 'Provincias',
              zones: 'Zonas',
            },
          },
          PROVIDERS: {
            title: 'Proveedores',
            distributionTypes: 'Tipos de Distribución',
            drugCompanies: 'Droguerías',
            logisticOperators: 'Operadores Logísticos',
            paybackEntities: 'Entidad Recupero',
            pharmacies: 'Farmacias',
            pharmacyGroups: 'Entidades Farmacéuticas',
          },
          TOOLS: {
            title: 'Herramientas',
            commercialAgreements: 'Convenio Comercial',
            coveragePrograms: 'Programas de Cobertura',
            debitCreditReasons: 'Motivos Debito-Credito',
            diagnoses: 'Diagnósticos',
            doctors: 'Médicos',
            medicalSpecialities: 'Especialidades',
            misValidaciones: 'Mis Validaciones',
            pathologies: 'Patologías',
            pathology: 'Patología',
            diagnosis: 'Diagnóstico',
            sapDistributionRules: 'Normas de Reparto SAP',
            incidentAreas: 'Areas de Derivación',
            incidentReasons: 'Razones de Incidencias',
            companies: 'Empresas',
            COMPANIES: {
              logo: 'Logo'
            }
          },
        },
      },
      cancel: 'Cancelar',
    },
    AUDIT: {
      title: 'Auditar',
      accept: 'Aceptar',
      treatments: 'Tratamientos',
      prescriptionItems: 'Prescripciones',
      openBeneficiary: 'Ver Beneficiario',
      openTreatments: 'Ver Tratamientos',
      openPrescription: 'Ver Prescripción',
      treatmentsEdited:
        '{count, plural, =0 {} one {1 tratamiento editado} other {{count} tratamientos editados}}',
      treatmentsAudited: 'Se han procesado {count} tratamientos al auditar',
      treatmentsAuditFailed: 'Han fallado {count} tratamientos al auditar',
      prescriptionsEdited:
        '{count, plural, =0 {} one {1 item editado} other {{count} items editados}}',
      prescriptionsAudited: 'Se han procesado {count} items al auditar',
      prescriptionsAuditFailed: 'Han fallado {count} items al auditar',
      financierInactive:
        'El financiador asociado al beneficiario se encuentra inactivo',
      diabetesCertificateTitle: 'Certificado de diabetes inválido',
      diabetesCertificateInvalid:
        'Beneficiario sin certificado de diabetes o con fecha fuera de rango, ¿Desea continuar?',
      disabilityCertificateTitle: 'Certificado de discapacidad inválido',
      disabilityCertificateInvalid:
        'Está intentando procesar una prescripción que posee medicamentos asociados a un programa de cobertura para discapacitados, sin embargo el beneficiario no tiene un certificado de discapacidad asociado, ¿Desea continuar?',
      dispatchDateValidates: 'Fecha de Prescripcion Vencida o Adelantada',
      prolongedTreatmentValidates:
        'Entrega 2 o más productos de mayor tamaño sin tratamiento prolongado',
      reasonDebitValidates: 'Debe Selecciona un Motivo de Debito',
    },
    CANCEL: {
      title: 'Cancelar',
      entityType: 'Tipo Entidad',
      entityId: 'Id Entidad',
      observations: 'Observación',
      revert: 'Revertir',
      cancelar: 'Cancelar',
      entityCanceled: 'Entidad Cancelada',
      entityNotCanceled:
        'La prescripción no puede ser cancelada porque está en estado de: ',
      prescriptionItemNotCanceled: 'El item no puede ser cancelado debido a que se encuentra en estado de: ',
      ENTITY_TYPES: {
        Prescription: 'Prescripción',
        Dispatch: 'Despacho',
        PaymentOrder: 'Orden de Pago',
        PrescriptionItem: 'Item Prescripción',
      },
      CONFIRM: {
        header: 'Cancelar item',
        message: 'Se cancelará la entidad {entityType} con id {entityId}'
      }
    },
    DISTRIBUTE: {
      title: 'Distribuir',
      drugCompany: 'Droguería',
      drugCompanyOrders: 'Pedidos a Droguería',
      pending: 'Pendientes',
      generated: 'Generados',
      transit: 'En tránsito',
      list: 'Listar',
      notifySAP: 'Notificar a SAP',
      getStatus: 'Obtener Estado',
      downloadOrderReport: 'Descargar Reporte de Orden',
      selectedItems:
        '{count, plural, =0 {} one {1 item seleccionado} other {{count} items seleccionados}}',
      orderCreated: 'Orden #{orderId} creada',
      orderCreatedManually: 'Orden #{orderId} creada manualmente',
      ordersCreated: 'Ordenes #{orders} creadas',
      itemsShipped: '{number} items procesados como Enviados',
      itemsReceived: '{number} items procesados como Recibidos',
      sapNotificationSent: 'Notificación SAP para Orden #{orderId} enviada',
      sapNotificationError: 'Error al notificar a SAP para Orden #{orderId}',
      excelReportGenerated: 'Reporte America generado para Orden #{orderId}',
      excelReportGenerationError:
        'Error al generar Reporte America para Orden #{orderId}',
      BeneficiaryInvalidCoverageProgramError:
        'No se puede procesar items con Programa de Cobertura del Beneficiario con fecha inválida',
      BeneficiaryMissingCoverageProgramError:
        'No se puede procesar items sin Programa de Cobertura para el Beneficiario',
      FinancierInactiveError:
        'No se puede procesar items con un Financiador inactivo',
      PharmacyInactiveError:
        'No se puede procesar items con una Farmacia inactiva',
      RedispenseInactiveError:
        'No se puede cargar una Prescripcion con Redespacho para una farmacia que no admite esta modalidad',
      DistributeMedicationDrugCompanyError:
        'Medicamento Droguería Alias faltante',
      DistributeMultipleDrugCompaniesError:
        'No se puede procesar un pedido a multiples Droguerías',
      DistributePharmacyAgreementRuleError:
        'Falta configurar Norma Operativa de Farmacia',
      DistributePharmacyDrugCompanyError:
        'Falta configurar Norma Droguería de Farmacia',
      InvalidStatusError:
        'Hay items con un cambio de estado reciente. Por favor, recargue la página.',
      VademecumNotFound: 'Vademecum no encontrado',
      InvalidDistributionTypeError: 'Tipo de Distribución inválido',
      returned: 'Se devolvio con exito el Prescription Item: N° {id}',
      return: 'Devolver',
      confirmReturnTitle: 'Devolver Prescripcion Item',
      confirmReturnMessage: '¿Desea devolver itemPrescripcion: N° {medicationDisplay} ?',
      returnError:'Error al devolver el Prescription Item: N° {id}',
      DistributeBillingRuleError: 'No se puede procesar items, Norma Facturacion del Financiador no configurada',
      PrescriptionReturnStatusError: 'No se puede devolver el item, recargue la página',
      loadingHeader: 'Procesando pedidos...',
      loadingMessage: 'Por favor espere, esto podría demorar varios minutos...'
    },

    LIQUIDATE: {
      title: 'Liquidar',
      DISPATCHES: {
        title: 'Presentaciones',
        withoutPermissionProcess: "No posee permisos para Procesar",
        LIST: {
          pending: 'Pendientes',
          presented: 'Presentados',
          dispatches: 'Despachos',
          saleDate:'Fecha de Venta',
          batches: 'Lotes',
          pharmacyBatchesGenerated:
            '{number} Lotes de farmacia generados, con los siguientes ids: {ids}',
          pharmacyBatchesNotAdd: 'No puede agregar Lotes en estado Presentado',
          financierNoAllowOtherFinanciers:
            'El Financiador {name} no permite Liquidación compartida con otros Financiadores',
        },
        EDIT: {
          retailPrice: 'Precio de Venta al Público',
          agreementCoverage: 'Cobertura Convenio',
          beneficiaryCoverage: 'A cargo Beneficiario',
          confirmCancelDispatch: 'Cancelar Despacho',
          confirmCancelDispatchMessage: '¿Desea cancelar este Despacho?',
          messageErrorDifference: 'El despacho presentado tiene diferencias respecto a la validación que son motivo de débito. Si tiene una autorización por escrito, adjuntela a la receta para evitar el débito.',
          messageErrorPresentedAmount: 'La cantidad presentada supera la cantidad validada.',
          messageErrorPresentedUnitPrice: 'El importe PVP difiere del importe validado.',
        },
      },
      LIQUIDATIONS: {
        logs: 'Logs de estado',
        EDIT: {
          general: 'General',
          pay: 'Pagar',
          items: 'Lotes',
          totals: 'Totales',
          total: 'Total',
          liquidatedAmountTotal: 'Total Liquidado',
          debitedAmount: 'Total Debitado',
          paymentDate: 'Fecha de Pago',
          expirationDate: 'Fecha de Vencimiento',
          paymentMethod: 'Metodo de Pago',
          process: 'Procesar',
          provider: 'Proveedor',
          invoice: 'Comprobante',
          totalAmount: 'Importe Total',
          succesPay: 'El pago de la liquidación se realizó exitosamente',
          informedPay: 'La liquidación fue informada exitosamente',
          errorPay: 'Error al realizar el pago',
          errorReceptionDate: 'Fecha Presentación adelantada o vencida',
          errorInactiveMedication: 'Producto dado de baja',
          errorReturnDispatch: 'El despacho debe ser devuelto',
          itemCanceledError: 'Ha ocurrido un error al intentar cancelar',
          itemCanceledContainBatchesError:
            'No es posible Cancelar liquidación con Lotes asociados',
          confirmCancelLiquidation: 'Cancelar liquidación',
          confirmCancelLiquidationMessage: '¿Desea cancelar esta liquidación?',
          confirmNewBatchCreated: 'Lote de liquidacion: { id } generado',
          adjustments: 'Ajustes',
           notifyEmail: "Estimados: Se envía comprobante de liquidación correspondiente al período { period } con fecha de pago { formattedPaymentDate }.\nSaludos cordiales.",
          liquidationNotifyError: 'La Farmacia/Entidad Farmacéutica no tiene configurado un correo electrónico de pagos'
        },
      },
      PHARMACY_BATCHES: {
        logs: 'Logs de estado',
        EDIT: {
          general: 'General',
          adjustments: 'Ajustes',
          items: 'Despachos',
          totals: 'Totales',
          presented: 'Presentado',
          liquidated: 'Liquidado',
          adjustment: 'Ajuste',
          retailPriceTotal: 'Importe PVP',
          financierTotal: 'Importe a Cargo',
          serviceBonus: 'Bonificación/Servicio',
          paymentTotal: 'Total a Pagar',
          prescriptions: 'Recetas',
          liquidate: 'Liquidar',
          auditDispatch: 'Auditar',
          merchandisePVPTotalPrice: 'Total PVP mercaderia',
          servicePVPTotalPrice: 'Total PVP servicio',
          service: 'Servicio',
          bonus: 'Bonificación',
          idItem: 'Item Id:',
          status: 'Estado:',
          audit: 'Auditar',
          audited: 'Auditado',
          attach: 'Adjuntar archivo',
          itemsTitle: 'Items',
          dispatch: 'Despacho',
          order: 'Orden',
          messagePayService:
            'Pago por servicio: devolver fotocopia sin troqueles',
          auditUser: 'Usuario auditor',
          unduDispatch: 'Revertir auditoria',
          changeStatus: 'Cambiar estado a Despachado',
        },
        CHANGE_STATUS_BACTH: {
          header: "Cambio de Estado de lote",
          message: "El lote se encuentra Auditado. ¿Desea revertir el estado a Presentado?",
        },
        AUDIT_DISPATCH: {
          items: 'Items Despacho',
          validated: 'Validado',
          presented: 'Presentado',
          adjustment: 'Ajuste',
          minTotalError: 'El Total a Pagar no puede ser menor a 0',
          coverage: 'Cobertura',
          amount: 'Cantidad',
          price: 'Precio',
          unitPrice: 'Precio Unitario',
          charged: 'A Cargo',
          paying: 'A Pagar',
          debitReason: 'Motivo Debito',
          alreadyExists: 'Ya existe',
          success: 'Despacho auditado exitosamente',
          title: '',
          pageTitle: '',
          changeSuccess: 'Cambios realizados exitosamente.',
          completeLiquidateValue: 'Completar datos de la liquidación',
        },
      },
      INVERSE_LOGISTICS: {
        title: 'Logística Inversa',
        LIST: {
          pending: 'Pendientes',
          received: 'Recibido',
        }
      },
      BILLING: {
        title: 'Facturación',
        printItem: 'Comprobante Pre Facturación',
        LIST: {
          PROCESSED: {
            title: 'Procesados',
            edit: 'Editar Comprobante Administrativo'
          },
          PENDDING: {
            title: 'Pendiente',
            itemsProcessed:
              '{number} items fueron asociados al comprobante administrativo id: {id}',
            CONFIRM: {
              header: 'Procesar todo',
              message: 'Se procesaran todos los items de la consulta actual.',
            },
            UPDATE_BILLED_PERCENT: {
              title: 'Actualizar Descuento Comercial',
              dispatchBilledPercent: 'Descuento Comercial Despacho',
              ruleBilledPercent: 'Descuento Comercial Norma',
              updateSuccess: 'Descuento Comercial actualizado',
            }
          },
        },
      },
      INDUSTRY_RECOVERY: {
        title: 'Recupero Industria',
        LIST: {
          PROCESSED: {
            title: 'Procesados',
            edit: 'Editar Comprobante Administrativo'
          },
          PENDDING: {
            title: 'Pendiente',
            itemsProcessed:
              '{number} items fueron asociados al comprobante administrativo id: {id}',
            CONFIRM: {
              header: 'Procesar todo',
              message: 'Se procesaran todos los items de la consulta actual.',
            }
          },
        },
      }
    },
    PRESCRIBE: {
      title: 'Prescribir',
      authorize: 'Autorizar Prescripción',
      audit: 'Auditar Prescripción',
      validate: 'Validar Prescripción',
      replicate: 'Replicar Prescripción',
      edit: 'Editar Prescripción',
      prescriptionCreated: 'Prescripción creada',
      prescriptionUpdated: 'Prescripción actualizada',
      miscData: 'Otros Datos',
      dateAfterProvisionDateError:
        'La fecha limite de prestación ya ha terminado',
      generatedOrders: 'Pedidos Generados',
      prescription: 'Prescripción',
      auditNeeded: 'La cobertura queda sujeta a Revisión de Auditoría',
      readonlyModeAuthorize:
        'La Prescripción no se puede autorizar y se cargó en modo de sólo lectura',
      readonlyModeValidate:
        'La Prescripción no se puede validar y se cargó en modo de sólo lectura',
      readonlyModeEdit:
        'La Prescripción no se puede editar y se cargó en modo de sólo lectura',
      coverageProgramMedicationsNotFound: 'No se encontraron Medicamentos',
      diabetesCertificateTitle: 'Certificado de diabetes inválido',
      diabetesCertificateInvalid:
        'Beneficiario sin certificado de diabetes o con fecha fuera de rango, ¿Desea continuar?',
      disabilityCertificateTitle: 'Certificado de discapacidad inválido',
      disabilityCertificateInvalid:
        'El beneficiario está marcado como discapacitado pero no tiene un certificado de discapacidad asociado o el certificado no se encuentra vigente ¿Desea continuar?',
      financierInactive:
        'El financiador asociado al beneficiario se encuentra inactivo',
      BeneficiaryInvalidCoverageProgramError:
        'Beneficiario con Programa de Cobertura fuera de fecha de validez',
      BeneficiaryMissingCoverageProgramError:
        'No se encuentra el Programa de Cobertura para el Beneficiario',
      BenefiaryCoverageProgramTitle:
        'Programa de Cobertura del beneficiario vencido',
      BenefiaryCoverageProgramInvalid:
        'Beneficiario con programa de cobertura vencido, ¿Desea continuar?',
      BenefiaryCoverageProgramExpired:
        'Beneficiario con programa de cobertura vencido',
      BeneficiaryMedicationCoverageTitle: 'Medicamento sin cobertura',
      BeneficiaryMedicationCoverage:
        'Uno de los productos no posee cobertura, ¿Desea continuar?',
      PharmacyInactiveError: 'La Farmacia seleccionada está inactiva',
      RedispenseInactiveError:
        'No se puede cargar una Prescripcion con Redespacho para una farmacia que no admite esta modalidad',
      validateUnauthorize:
        'Alerta: usuario no se encuentra autorizado a realizar Validación',
      validateDistributionTypeRequired:
        'Alerta: Debe seleccionar un tipo de distribución',
      PrescriptionMedicationWasNotFoundOnCoverageProgram:
        'Medicamento no encontrado en el programa de cobertura.',
      readOnlyUser: 'No está autorizado para realizar la operación solicitada.',
      PrescriptionAuthorizedAmountError: 'El producto seleccionado supera la cantidad de unidades autorizadas',
      PrescriptionCannotSelect: 'La prescripción no puede ser seleccionada para ser procesada.',
      diagnosisIsMandatory: "Se debe indicar el diagnostico para los productos seleccionados",
      SEARCH: {
        title: 'Buscar',
        searchById: 'Buscar por Id. Prescripción',
        searchByBeneficiary: 'Buscar por Beneficiario',
        prescriptionId: 'Id. Prescripción',
        beneficiaryData: 'Credencial/Documento/Nombre Beneficiario',
        searchPrescription: 'Buscar Prescripción',
        searchPrescriptions: 'Buscar Pedido',
        prescriptionLoaded: 'Se ha cargado la Prescripción',
        prescriptionNotFound: 'No se ha encontrado la Prescripción',
        reset: 'Cancelar y cargar de nuevo',
        newPrescription: 'Nueva prescripción',
      },
      BENEFICIARY: {
        title: 'Beneficiario',
        search: 'Buscar por Documento / Credencial / CUIL / Nombre',
        searchInIncident:
          'Buscar beneficiario por Documento / Credencial / CUIL / Nombre',
        credential: 'Credencial',
        financier: 'Financiador',
        beneficiaryErrorNotActive: 'Beneficiario Inactivo',
        beneficiaryExist: 'Beneficiario existente',
        financierErrorNotActive: 'Financiador Inactivo',
        BeneficiaryHasPrescriptionsError: 'El/Los programa(s) de cobertura {cpNames} no puede(n) ser eliminado(s) porque el beneficiario tiene prescripciones o tratamientos asociados.'
      },
      DOCTOR: {
        title: 'Médico Prescriptor',
        search: 'Buscar por Licencia / Nombre',
        license: 'Número Matrícula',
        lastName: 'Apellido',
        firstName: 'Nombre',
      },
      LOGISTICS: {
        title: 'Datos Logísticos de Entrega',
        pharmacyErrorNotActive: 'Farmacia Inactiva',
        beneficiaryAddress: 'Seleccionar Domicilio del Beneficiario',
        beneficiaryShippingAddress:
          'Seleccionar Domicilio de Envío del Beneficiario',
        beneficiaryShippingPharmacyAddress:
          'Seleccionar Domicilio de la Farmacia del Beneficiario',
        pharmacyDebitsShippingAddress: 'Seleccionar Domicilio de envío Débitos',
        beneficiaryPharmacy: 'Seleccionar Farmacia del Beneficiario',
      },
      MEDICATIONS: {
        title: 'Medicamentos Prescritos',
        addMedication: 'Añadir Medicamento',
        editMedication: 'Editar Medicamento',
        authorizeMedication: 'Autorizar Medicamento',
        validateMedication: 'Validar Medicamento',
        noMedications: 'No se han cargado Medicamentos',
        medicationAlreadyExistsErrorTitle: 'Un medicamento que intenta seleccionar ya existe',
        medicationAlreadyExistsError: 'Ya existe el medicamento seleccionado',
        status: 'Estado',
        monodrug: 'Monodroga',
        medication: 'Medicamento',
        administrationRoute: 'Via de Administración',
        dosageForm: 'Forma Farmacéutica',
        program: 'Programa',
        diagnosis: 'Diagnóstico',
        dosage: 'Dosis',
        period: 'Intervalo',
        deliverAmountError: 'Excede el límite. Por favor, ingrese un valor válido.',
        search: 'Buscar por monodroga / troquel - medicamento presentacion',
        PERIODS: {
          every6Hours: 'Cada 6 Horas',
          every8Hours: 'Cada 8 Horas',
          every12Hours: 'Cada 12 Horas',
          every24Hours: 'Cada 24 Horas',
          otherDay: 'Interdiario',
          weekly: 'Semanal',
          biweekly: 'Quincenal',
          monthly: 'Mensual',
        },
        days: 'Días',
        prescribedAmount: 'Cantidad Prescrita',
        authorizedAmount: 'Cantidad Autorizada',
        issuedAmount: 'Cantidad Entregada',
        deliverAmount: 'Entregar',
        specialCoverage: 'Cobertura Especial',
        prescribesBrand: 'Prescribe Marca',
        prescribedBrandOnly: 'No admite reemplazo de Marca',
        codeATC: 'Código ATC',
        financierPercentage: '% Financiador',
        affiliatePercentage: '% Afiliado',
        price: 'Precio',
        total: 'Total',
        billedPercent: '% a Facturar',
        indications: 'Indicaciones Médicas',
        needsOne: 'Al menos un Medicamento es requerido.',
        potency: 'Potencia',
        auditObservations: 'Observaciones Auditoría',
        multipleCoverageProgramsError:
          'Una Prescripción no puede tener medicamentos de multiples Programas de Cobertura',
        deliverMedication: 'Entregar Medicamento',
      },
    },
    PRESCRIPTIONS: {
      title: 'Prescripciones',
      byPrescriptions: 'Prescripciones',
      byItems: 'Por Medicamentos',
      historical: 'Historico',
      showPrescription: 'Ver Prescripción',
      prescriptionsList: 'Listado de Prescripciones',
      noPrescriptionsFound: 'No se han encontrado Prescripciones',
      prescriptionNumber: 'Número Receta',
      beneficiary: 'Beneficiario',
      financier: 'Financiador',
      doctor: 'Doctor',
      createdAt: 'Fecha Carga',
      date: 'Fecha Prescripción',
      status: 'Estado',
      observations: 'Observaciones',
      duplicate: 'Duplicar Prescripción',
      duplicateError:
        'Programa de Cobertura inexistente para el Financiador asociado a la Prescripción',
      particionateItem: 'Particionar Item',
      authorize: 'Autorizar Prescripción',
      validate: 'Validar Prescripción',
      provisionLimitDateError: 'La fecha no puede ser menor al día de hoy',
      validateDatePrescriptionOverdue: 'La fecha de prescripcion esta vencida o adelantada',
      validatePrescriptionValidityDays: 'No es posible validar la fecha de prescripcion seleccionada',
      dispatch: 'Despacho',
      prescriptionNotifyPharmacyError:
        'La prescripción no tiene farmacia configurada',
      itemLogs: 'Logs de Estado',
      printPrescription: 'Imprimir Prescripción',
      printDispatch: 'Imprimir Despacho',
      incidents: 'Incidencias',
      proccessManual: 'Proceso manual',
      getDDSStatus: 'Obtener estado del Item Prescripcion',
      ddsOrderStatus: 'Ver estado del pedido',
      editInfoDrugCompany: 'Editar info Drogueria',
      getStock: 'Consultar Stock',
      ITEMS: {
        authorize: 'Autorizar',
        reject: 'Rechazar',
        approve: 'Aprobar',
        receive: 'Recibir',
        dispense: 'Dispensar',
        print: 'Imprimir',
        deliver: 'Entregar',
        statusDrugCompany: 'Estado droguería',
        estimatedEntryDate: 'Fecha estimada ingreso',
        delivery: 'Requiere envío a domicilio',
        succesInfo: 'El ingreso de la información se realizó correctamente',
        infoDrugCompanySuccess: 'Información de droguería actualizada',
        errorInfo: 'Hubo un error al ingresar la información',
        logObservations: 'Observaciones',
        receiptNumberRequired: 'El número de recibo es requerido para el ítem con ID: {id}',
      },
    },
    REPORTS: {
      execute: 'Ejecutar Reporte',
      loadingHeader: 'Ejecutando reporte',
      loadingMessage: 'Por favor espere, esto podría demorar varios minutos...'
    },
    DASHBOARD: {
      title: 'Dashboard',
    },
    VALIDATE: {
      title: 'Validar',
      distributeByDrugCompany: 'Los productos serán enviados por la Droguería',
      MODAL: {
        title: 'Confirmación de Prescripción',
        message: 'La prescripción generará un pedido a la Droguería, ¿desea continuar?',
      }
    },
    SETUP: {
      title: 'Configurar',
      ADMIN: {
        title: 'Administración',
        USERS: {
          general: 'General',
          userAlreadyExistsError:
            'Ya existe un usuario para el email seleccionado',
          disableUser: 'Deshabilitar Usuario',
        },
        GROUPS: {
          title: 'Roles',
          newTitle: 'Creando nuevo Rol',
          editTitle: 'Rol {display}',
          addGroup: 'Agregar Rol',
          newGroup: 'Nuevo Rol',
          showGroup: 'Ver Rol',
          editGroup: 'Editar Rol',
          saveGroup: 'Guardar Rol',
          removeGroup: 'Eliminar Rol',
          groupsList: 'Listado de Roles',
          selectedGroups: '{selected} rol(es) seleccionado(s)',
          noGroupsFound: 'No se han encontrado Roles',
          name: 'Nombre',
          description: 'Descripción',
          groupAlreadyExistsError:
            'Ya existe un rol con el nombre seleccionado',
          groupCreated: 'Rol creado',
          groupUpdated: 'Rol guardado',
          permissions: 'Permisos',
          users: 'Usuarios',
          selectedUsers: '{selected} usuario(s) seleccionado(s)',
          financiers: 'Financiadores',
          drugCompanies: 'Droguerías',
          reports: 'Reportes',
          incidentArea: 'Área derivación de incidencias',
        },
      },
      OPERATIONS: {
        title: 'Operaciones',
        BENEFICIARIES: {
          DISABILITY_CERTIFICATE: {
            hasNotDisabilityProgram:
              'No se encontró ningún programa de discapacidad en los programas de cobertura asociados al beneficiario',
            invalidTitle: 'Certificado de discapacidad inválido',
            invalidMessage:
              'El beneficiario está marcado como discapacitado pero no tiene un certificado de discapacidad asociado o el certificado no se encuentra vigente ¿Desea continuar?',
          },
          DIABETES_CERTIFICATES: {
            title: 'Certificados de Diabetes de Beneficiario {display}',
            diabetesCertificates: 'Certificados de Diabetes',
            backToList: 'Volver a Listado',
            backToEdit: 'Volver a Beneficiario',
          },
          TREATMENTS: {
            general: 'General',
            medications: 'Productos',
            title: 'Tratamientos de Beneficiario {display}',
            treatments: 'Tratamientos',
            backToList: 'Volver a Listado',
            backToEdit: 'Volver a Beneficiario',
            medicationNotCoveredError:
              'El Medicamento seleccionado no tiene cobertura',
            monodrugNotCoveredError:
              'La Monodroga seleccionada no tiene cobertura',
            logs: 'Logs de estado',
            EDIT: {
              confirmDeleteTreatment: '¿Desea eliminar el tratamiento?',
              confirmDeleteTreatmentMessage: 'Eliminar tratamiento',
              confirmDelete: 'Tratamiento eliminado',
              errorDelete: 'No se pudo eliminar el tratamiento',
            },
          },
          PRESCRIPTIONS: {
            prescriptions: 'Prescripciones',
            title: 'Prescripciones del Beneficiario: {display}',
          },
        },
        COVERAGE_PROGRAMS: {
          multipleVademecumsError: 'No puede haber Vademecums duplicados',
        },
        DOCTORS: {
          title: 'Médicos',
          noDoctorsFound: 'No se han encontrado Médicos',
          sisaData: 'Datos SISA',
          removeSisaData: 'Quitar datos SISA',
          validateSisa: 'Validar SISA',
          searchSisa: 'Buscar Médico en SISA',
          manualEntry: 'Ingresar Manualmente',
          sisaValidationDate: 'Fecha Validación SISA',
          sisaValidityDate: 'Fecha Vigencia SISA',
          refesCode: 'Código REFES',
          licenseNumber: 'Número Matrícula',
          jurisdiction: 'Jurisdicción',
          status: 'Estado',
          noLicensesFound: 'No se han encontrado licencias',
          doctorAlreadyExistsError:
            'Ya existe un Médico con el documento seleccionado',
          needsOneLicenseError: 'Al menos una licencia debe ser definida',
          doctorWithoutSisaData: 'Profesional no validado en SISA',
          invalidDoctor: 'Médico inválido',
          productUunsubscribed: 'Producto dado de baja',
        },
        FINANCIERS: {
          general: 'General',
          codes: 'Códigos de Sistemas Externos',
          billingRules: 'Norma Facturación',
          paybackEntities: 'Norma Recupero Industria',
          drugCompanies: 'Norma Droguerías',
          agreementRules: 'Normas Operativas',
          updatedAgreementRulesAutomaticAudit:
            'Se ha actualizado la Auditoría Automática de las Normas Operativas',
          financierAlreadyExistsError:
            'Ya existe un Financiador con el CUIT seleccionado',
          IMPORT_BENEFICIARIES: {
            title: 'Importar Padrón',
            action: 'Acción',
            insert: 'Insertar',
            delete: 'Eliminar',
            update: 'Actualizar',
            file: 'Archivo',
            result: 'Resultado',
            totalCount:
              'Se encontraron {count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} a importar',
            insertedCount:
              '{count, plural, one {1 Beneficiario insertado} other {{count} Beneficiarios insertados}} ',
            updatedCount:
              '{count, plural, one {1 Beneficiario actualizado} other {{count} Beneficiarios actualizados}}',
            deletedCount:
              '{count, plural, one {1 Beneficiario desactivado} other {{count} Beneficiarios desactivados}} ',
            ignoredCount:
              '{count, plural, one {1 Beneficiario ignorado} other {{count} Beneficiarios ignorados}}',
            invalidRowErrorsCount:
              '{count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} con datos inválidos',
            financierPermissionErrorsCount:
              '{count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} con financiador fuera de los permisos de usuario',
            financierErrorsCount:
              '{count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} con financiador inválido',
            coverageProgramErrorsCount:
              '{count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} con programa de cobertura inválido',
            financierPMIErrorsCount:
              '{count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} con PMI de financiador inválido',
            unknownErrorsCount:
              '{count, plural, one {1 Beneficiario} other {{count} Beneficiarios}} con errores no esperados',
            invalidRowError: 'Fila: {index}',
            financierPermissionError:
              'Fila: {index} - Financiador {financierId}',
            financierError: 'Fila: {index} - Financiador {financierId}',
            coverageProgramError:
              'Fila: {index} - Financiador {financierId} - Programa de Cobertura {coverageProgramId}',
            financierPMIError: 'Fila: {index} - Financiador {financierId}',
            unknownError: 'Fila: {index} - {data}',
          },
        },
        INCIDENT: {
          title: 'Incidencias',
          beneficiaryTitle: 'Incidencias del Beneficiario: {display}',
          applicant: 'Informante',
          personalAssigned: 'Personal Asignado',
          description: 'Descripción de la Incidencia',
          descriptionLogs: 'Logs de Descripción',
          beneficiary: 'Beneficiario',
          editBeneficiary: 'Ver',
          messageBeneficiary: "Mensaje al Beneficiario",
          backToEdit: "Volver al beneficiario",
          backToList: 'Volver al listado',
        },
        MEDICATIONS: {
          title: 'Medicamentos',
          ALFABETA: {
            title: 'Procesar Alfabeta',
            processedMessage:
              'El pedido de procesamiento se realizó exitosamente',
          },
          MEDICATIONS: {
            general: 'General',
            references: 'Referencias',
            priceHistory: 'Histórico de Precios',
            drugCompanyAlias: 'Medicamento Droguería Alias',
            multipleDrugCompaniesError: 'No puede haber Droguerías duplicadas',
          },
        },
        MIS_VALIDACIONES: {
          title: 'Procesar MisValidaciones',
          startDate: 'Desde',
          endDate: 'Hasta',
          codvalidacion: 'Codigo de validacion',
          codigo_presentacion: 'Codigo de presentacion',
          pharmacyCurf: 'Cuf Farmacia',
          financiers: 'Financiadores',
          processedMessage:
            'El pedido de procesamiento se realizó exitosamente',
        },
        PHARMACIES: {
          general: 'General',
          payments: 'Pagos',
          codes: 'Códigos de Sistemas Externos',
          drugCompanies: 'Droguerías',
          agreementRules: 'Normas Liquidación',
          contacts: 'Contactos',
          invalidEmail: "Por favor, ingrese un correo electrónico válido",
          multipleDrugCompaniesError: 'No puede haber Droguerías duplicadas',
          multipleAgreementRulesError:
            'No puede haber Normas de Liquidación duplicadas (Mismo Financiador, Programa de Cobertura y Vademecum)',
          institutuionalDieNotEnabled:
            'No Habilitada para la entrega de Troqueles Institucionales',
          AGREEMENT_RULES: {
            title: 'Normas de Liquidación {display}',
            agreementsRules: 'Normas de Liquidación',
            backToList: 'Volver a Listado',
            backToEdit: 'Volver a Farmacia',
          },
        },
        PHARMACY_GROUPS: {
          payments: 'Pagos',
          codes: 'Códigos de Sistemas Externos',
          others: 'Otros',
        },
        VADEMECUMS: {
          title: 'Vademecums',
          editItems: 'Editar Medicamentos',
          ITEMS: {
            title: 'Medicamentos de Vademecum {name}',
            items: 'Medicamentos',
            vademecum: 'Vademecum',
            itemsCount: 'medicamentos',
            deleteSelected: 'Eliminar seleccionados ({count})',
            deleteSelectedConfirm: '¿Está seguro que desea eliminar los {count} elementos seleccionados?',
            itemsDeleted: 'Elementos eliminados correctamente',
            deleteError: 'Error al eliminar los elementos',
            monodrug: 'Monodroga',
            dieCode: 'Troquel',
            medication: 'Medicamento',
            presentation: 'Presentación',
            potency: 'Potencia',

            backToList: 'Volver a Listado',
            backToEdit: 'Volver a Vademecum',
            addMedication: 'Añadir Medicamento',
            addMonodrug: 'Añadir Monodroga',
            selectedItems: '{selected} medicamento(s) seleccionado(s)',
            noItemsFound: 'No se han encontrado Medicamentos',
            itemAdded: 'Medicamento añadido',
            itemRemoved: 'Medicamento removido',
            duplicatedItem: 'Medicamento ya añadido',

            selectMonodrug: 'Seleccionar Monodroga',
            selectMedication: 'Seleccionar Medicamento',

            importMedications: 'Importar Medicamentos',
            importMonodrugs: 'Importar Monodrogas',

            importTotalCount:
              'Se encontraron {count, plural, one {1 {item}} other {{count} {collection}}} a importar',
            importInsertedCount:
              '{count, plural, one {1 {item} {gender, select, female{añadida} other{añadido}}} other {{count} {collection} {gender, select, female{añadidas} other{añadidos}}}}',
            importUpdatedCount:
              '{count, plural, one {1 {item} {gender, select, female{actualizada} other{actualizado}}} other {{count} {collection} {gender, select, female{actualizadas} other{actualizados}}}}',
            importExistingCount:
              '{count, plural, one {1 {item} ya existente} other {{count} {collection} ya existentes}}',
            importNotFoundCount:
              '{count, plural, one {1 {item} no {gender, select, female{encontrada} other{encontrado}}} other {{count} {collection} no {gender, select, female{encontradas} other{encontrados}}}}',
            importInvalidCount:
              '{count, plural, one {1 {item} {gender, select, female{inválida} other{inválido}}} other {{count} {collection} {gender, select, female{inválidas} other{inválidos}}}}',
          },
        },
        PLACES: {
          title: 'Lugares',
          ZONES: {
            title: 'Zonas',
            editLocalities: 'Editar Localidades',
            LOCALITIES: {
              title: 'Localidades de Zona {name}',
              localities: 'Localidades',
              zone: 'Zona',
              backToList: 'Volver a Listado',
              backToEdit: 'Volver a Zona',
              selectLocality: 'Seleccionar Localidad',
              addLocality: 'Añadir Localidad',
              selectedLocalities: '{selected} localidad(es) seleccionada(s)',
              noLocalitiesFound: 'No se han encontrado Localidades',
              localityAdded: 'Localidad añadida',
              localityRemoved: 'Localidad removida',
              duplicatedLocality: 'Localidad ya añadida',
              region: 'Región',
              selectRegion: 'Seleccionar Región',
              selectLocalities: 'Seleccionar Localidades',
              localitiesAdded: 'Localidades seleccionadas añadidas exitosamente',
              addLocalities: 'Añadir Localidades Seleccionadas',
            },
          },
        },
      },
    },
  },
};
